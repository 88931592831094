import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import { ColorNeutral600 } from '@hub/design-tokens/colors';
import { Button } from '@ui/button';
import { Typography, TypographyType } from '@ui/typography';

import { BulkActionsWrapper } from './BulkAction.styles';

export const BulkActions = ({ children }: { children: React.ReactNode }) => {
  return <BulkActionsWrapper>{children}</BulkActionsWrapper>;
};

BulkActions.SelectedCount = function SelectedCount({ count }: { count: number }) {
  return (
    <Typography type={TypographyType.LABEL} color={ColorNeutral600}>
      <FormattedMessage id="bulkActions.selected" values={{ count }} />
    </Typography>
  );
};

BulkActions.SelectAllTableButton = function SelectAllTableButton({
  allSelected,
  selectionMode,
  onSelectAll,
  totalCount,
}: {
  allSelected: boolean;
  selectionMode: 'all' | 'table';
  onSelectAll: () => void;
  totalCount: number;
}) {
  if (!allSelected) return null;

  return (
    <Button variant="secondary" onClick={onSelectAll}>
      {selectionMode === 'all' ? (
        <FormattedMessage id="bulkActions.undoSelection" />
      ) : (
        <FormattedMessage id="bulkActions.selectAllItems" values={{ totalCount }} />
      )}
    </Button>
  );
};

BulkActions.SelectAllButton = function SelectAllButton({
  allSelected,
  setSelectAll,
}: {
  allSelected: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Button variant="secondary" onClick={() => setSelectAll(!allSelected)}>
      {allSelected ? (
        <FormattedMessage id="bulkActions.undoSelection" />
      ) : (
        <FormattedMessage id="bulkActions.selectAll" />
      )}
    </Button>
  );
};

BulkActions.displayName = 'BulkActions';
