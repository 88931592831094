import { Maybe } from 'graphql/jsutils/Maybe';
import { useFormContext } from 'react-hook-form';

import { usePaymentTermOptions } from '@bootstrap/constants/paymentTermOptions';
import { getDirectPaymentMaximum, getIndirectPayment } from '@bootstrap/utils/aggregations';
import { getValueAsNumber } from '@bootstrap/utils/getValueAsNumber';
import { truncate, calculatePercentageOfAmount } from '@bootstrap/utils/number';
import {
  ClientInvoiceStatus,
  ClientTransactionStatementStatus,
  CommonInvoiceFormValues,
  InvoiceFormFields,
} from '@factoring/modules/invoice/Invoice.types';
import { BranchForInvoiceEditQuery, InvoiceQuery } from '@factoring/types/generated.hooks';

export const getClientInvoiceStatus = (status: InvoiceStatus): ClientInvoiceStatus => {
  if (['CREATED'].includes(status)) return 'DRAFT';
  if (['SUBMITTED', 'PENDING_DEBTOR_APPROVAL', 'ON_HOLD'].includes(status)) return 'PENDING';
  if (['APPROVED', 'TO_BE_PAID', 'OPEN'].includes(status)) return 'OPEN';
  if (['CLOSED', 'FINALIZED'].includes(status)) return 'PAID';
  if (['DISAPPROVED'].includes(status)) return 'DISAPPROVED';
  return 'DRAFT';
};

export const getClientTransactionStatementStatus = (status: InvoiceStatus): ClientTransactionStatementStatus => {
  if (['OPEN', 'CLOSED', 'FINALIZED'].includes(status)) return 'PAID';
  return 'OPEN'; // 'APPROVED', 'TO_BE_PAID' are open
};

export const transformClientInvoiceStatusToInvoiceStatus = (status?: ClientInvoiceStatus): InvoiceStatus[] => {
  if (status === 'DRAFT') return ['CREATED'];
  if (status === 'PENDING') return ['SUBMITTED', 'PENDING_DEBTOR_APPROVAL', 'ON_HOLD'];
  if (status === 'OPEN') return ['APPROVED', 'TO_BE_PAID', 'OPEN'];
  if (status === 'PAID') return ['CLOSED', 'FINALIZED'];
  if (status === 'DISAPPROVED') return ['DISAPPROVED'];
  return [];
};

export const useInvoiceFormUtils = () => {
  const { getValues, setValue } = useFormContext();
  const paymentTerms = usePaymentTermOptions();

  const updateGSplitAmount = () => {
    const gSplitPercent = getValues(InvoiceFormFields.G_SPLIT_PERCENT);
    const amountWithVat = getValueAsNumber(getValues(InvoiceFormFields.AMOUNT_WITH_VAT), 0);
    setValue(InvoiceFormFields.G_SPLIT_AMOUNT, calculatePercentageOfAmount(amountWithVat, gSplitPercent));
  };

  const updateIndirectPayment = () => {
    const depot = getIndirectPayment({
      amountWithVat: getValueAsNumber(getValues(InvoiceFormFields.AMOUNT_WITH_VAT), 0),
      directPayment: getValueAsNumber(getValues(InvoiceFormFields.DIRECT_PAYMENT), 0),
      gSplitPercent: getValues(InvoiceFormFields.G_SPLIT_PERCENT),
    });
    setValue(InvoiceFormFields.INDIRECT_PAYMENT, depot);
  };

  const updateDirectPayment = () => {
    const amountWithVat = getValueAsNumber(getValues(InvoiceFormFields.AMOUNT_WITH_VAT), 0);
    const gSplitPercent = getValues(InvoiceFormFields.G_SPLIT_PERCENT);
    const defaultDepotPercent = getValues(InvoiceFormFields.DEPOT_PERCENT);
    const directPaymentMaximum = getDirectPaymentMaximum(amountWithVat, gSplitPercent, defaultDepotPercent);
    if (defaultDepotPercent !== 0 || amountWithVat < 0 || amountWithVat > 0) {
      setValue(InvoiceFormFields.DIRECT_PAYMENT, directPaymentMaximum.toString());
    } else {
      const positiveDirectPayment = Math.abs(getValueAsNumber(getValues(InvoiceFormFields.DIRECT_PAYMENT), 0));
      setValue(InvoiceFormFields.DIRECT_PAYMENT, positiveDirectPayment.toString());
    }
  };

  const updateAmounts = () => {
    updateGSplitAmount();
    updateDirectPayment();
    updateIndirectPayment();
  };

  const prefillFieldsWithBranchData = (data?: BranchForInvoiceEditQuery) => {
    setValue(
      InvoiceFormFields.PAYMENT_TERM,
      paymentTerms.find((option) => option.value === data?.branch.paymentTerm) ?? null,
    );

    if (data?.branch.defaultGsplitPercentage && parseFloat(data.branch.defaultGsplitPercentage) !== 0) {
      setValue(InvoiceFormFields.INCLUDE_G_SLIT, true);
      // defaultGsplitPercentage is stored in DB as coefficient like 0.25 instead 25, we should multiply it to 100
      setValue(InvoiceFormFields.G_SPLIT_PERCENT, parseFloat(data.branch.defaultGsplitPercentage) * 100);
    } else {
      setValue(InvoiceFormFields.INCLUDE_G_SLIT, false);
      setValue(InvoiceFormFields.G_SPLIT_PERCENT, 0);
    }

    if (data?.branch.defaultDepotPercentage && parseFloat(data.branch.defaultDepotPercentage) !== 0) {
      setValue(InvoiceFormFields.INCLUDE_DEPOT_PERCENT, true);
      // defaultDepotPercentage is stored in DB as coefficient like 0.25 instead 25, we should multiply it to 100
      setValue(InvoiceFormFields.DEPOT_PERCENT, parseFloat(data.branch.defaultDepotPercentage) * 100);
    } else {
      setValue(InvoiceFormFields.INCLUDE_DEPOT_PERCENT, false);
      setValue(InvoiceFormFields.DEPOT_PERCENT, 0);
    }

    updateAmounts();
  };

  return {
    updateGSplitAmount,
    updateDirectPayment,
    updateIndirectPayment,
    updateAmounts,
    prefillFieldsWithBranchData,
  };
};

export const getCommonInvoiceFormValues = (
  invoice: InvoiceQuery['invoice'],
  defaultDepotPercentage: Maybe<string>,
): Omit<CommonInvoiceFormValues, 'attachments'> => {
  const isGSplitIncluded = parseFloat(invoice.gsplitPercentage) !== 0;
  const gSplitPercentage = parseFloat(invoice.gsplitPercentage) * 100;
  const defaultDepotPercent = parseFloat(defaultDepotPercentage ?? '0') * 100;
  const isDefaultDepotIncluded = defaultDepotPercent !== 0;
  const amountWithVat = parseFloat(invoice.amountWithVat);
  const gPart = parseFloat(invoice.gPart);

  const directPayment = parseFloat(invoice.directPayment);

  return {
    [InvoiceFormFields.BRANCH]:
      invoice.issuedTo && invoice.issuedToBranchId
        ? {
            label: invoice.issuedTo,
            value: invoice.issuedToBranchId,
          }
        : null,
    [InvoiceFormFields.INVOICE_NUMBER]: invoice.invoiceNumber ?? '',
    [InvoiceFormFields.INVOICE_DATE]: invoice.issueDate ?? new Date().toISOString(),
    [InvoiceFormFields.PO_NUMBER]: invoice.poNumber ?? '',
    [InvoiceFormFields.G_SPLIT_PERCENT]: gSplitPercentage,
    [InvoiceFormFields.AMOUNT_WITH_VAT]: invoice.amountWithVat,
    [InvoiceFormFields.VAT_AMOUNT]: truncate(amountWithVat - parseFloat(invoice.amount)).toString(),
    [InvoiceFormFields.G_SPLIT_AMOUNT]:
      isGSplitIncluded && gPart > 0
        ? parseFloat(invoice.gPart)
        : calculatePercentageOfAmount(amountWithVat, gSplitPercentage),
    [InvoiceFormFields.DIRECT_PAYMENT]: directPayment.toString(),
    [InvoiceFormFields.INDIRECT_PAYMENT]: getIndirectPayment({
      amountWithVat,
      directPayment: directPayment,
      gSplitPercent: gSplitPercentage,
    }),
    [InvoiceFormFields.INCLUDE_G_SLIT]: isGSplitIncluded,
    [InvoiceFormFields.DEPOT_PERCENT]: defaultDepotPercent,
    [InvoiceFormFields.INCLUDE_DEPOT_PERCENT]: isDefaultDepotIncluded,
    [InvoiceFormFields.REFERENCE]: invoice.reference ?? '',
    [InvoiceFormFields.PROJECT_NAME]: invoice.projectName ?? '',
  };
};
