import { FormattedMessage, useIntl } from 'react-intl';

import { sum } from '@bootstrap/utils/aggregations';
import { FormattedCurrency } from '@bootstrap/utils/formatCurrency';
import { renderAmount } from '@bootstrap/utils/renderAmount';
import { getIdFromUUID } from '@bootstrap/utils/uuid';
import { Button } from '@ui/button';
import { Dialog } from '@ui/dialog';
import { ITableColumn, Table } from '@ui/table';

interface IInvoicesTableDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  selectedRows: Invoice[];
}

export const InvoicesTableDialog = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  selectedRows,
}: IInvoicesTableDialogProps) => {
  const { formatMessage } = useIntl();

  const columns: ITableColumn<Invoice>[] = [
    {
      name: <FormattedMessage id="field.invoiceNumber" />,
      id: 'identifier',
      cell: (row) => row.invoiceNumber || getIdFromUUID(row.uuid),
      Footer: <FormattedMessage id="field.total" />,
    },
    {
      id: 'AMOUNT_WITH_VAT',
      name: formatMessage({ id: 'field.totalAmount' }),
      cell: (row) => renderAmount({ amount: Number(row.amountWithVat), currency: row.currency }),
      right: true,
      Footer: <FormattedCurrency value={sum(selectedRows, 'amountWithVat')} />,
    },
  ];

  return (
    <Dialog
      size="s"
      isOpen={isOpen}
      onClose={() => onClose()}
      title={<FormattedMessage id="invoices.submitDialog.title" />}
      description={<Table columns={columns} data={selectedRows} keyField="uuid" />}
      actions={[
        <Button variant="secondary" onClick={() => onClose()} key="1">
          <FormattedMessage id="action.goBack" />
        </Button>,
        <Button variant="positive" isLoading={isLoading} onClick={onSubmit} key="2">
          <FormattedMessage id="action.yesSubmit" />
        </Button>,
      ]}
    />
  );
};

export default InvoicesTableDialog;
