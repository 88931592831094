import { useLoadLocaleData as useSharedLoadLocaleData } from '@bootstrap/hooks/useLoadLocaleData';
import { Locale } from '@bootstrap/types/locale';

import { loadLocaleData } from './effects';

export const useLoadLocaleData = (): {
  language: Locale;
  setLanguage: (lang: Locale) => void;
  messages?: Record<string, string>;
} => {
  const { locale, setLocale, messages } = useSharedLoadLocaleData({ loadLocaleData });

  return {
    language: locale,
    messages,
    setLanguage: setLocale,
  };
};
