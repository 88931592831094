import { FormattedMessage } from 'react-intl';

import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';
import { formatDate } from '@bootstrap/utils';
import { FormattedCurrency } from '@bootstrap/utils/formatCurrency';
import { ColorNegative600 } from '@hub/design-tokens';
import { Status } from '@ui/status';
import { ITableColumn } from '@ui/table';

import { ClientTransactionStatement } from '../TransactionStatement.types';

export const transactionStatementsTableColumns = (isSortable = true): ITableColumn<ClientTransactionStatement>[] => [
  {
    id: 'identifier',
    name: <FormattedMessage id="transactionStatement.transactionNumber" />,
    cell: (row) => row.identifier || EN_DASH,
    sortable: isSortable,
  },
  {
    id: 'project',
    name: <FormattedMessage id="transactionStatement.project" />,
    cell: (row) => row.project || EN_DASH,
  },
  {
    id: 'transaction_date',
    name: <FormattedMessage id="field.date" />,
    cell: (row) => formatDate(row.transactionDate),
    sortable: isSortable,
  },
  {
    id: 'amount',
    name: <FormattedMessage id="transactionStatement.transactionAmount" />,
    cell: (row) =>
      row.amount ? (
        <span style={{ color: parseFloat(row.amount) < 0 ? ColorNegative600 : undefined }}>
          <FormattedCurrency value={parseFloat(row.amount)} currency={row.currency} />
        </span>
      ) : (
        EN_DASH
      ),
    right: true,
    sortable: isSortable,
  },
  {
    id: 'status',
    name: <FormattedMessage id="field.status" />,
    cell: () => (
      <Status type="success">
        <FormattedMessage id="status.paid" />
      </Status>
    ),
    right: true,
  },
];
