import { useFormContext, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { capitalize } from '@bootstrap/utils/changeCase';
import { Control } from '@ui/control';
import { Flex } from '@ui/flex';
import { IControlledSwitchProps } from '@ui/switch/ControlledSwitch.types';
import { Switch } from '@ui/switch/Switch';
import { Typography, TypographyType } from '@ui/typography';

export function ControlledSwitch({
  name,
  registerOptions,
  label,
  variant = 'text',
  direction,
  helperText,
  ...props
}: IControlledSwitchProps) {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field, fieldState: { error } }) => {
        return (
          <Control
            isErrorShown={!!error}
            error={error?.message}
            name={name}
            label={label}
            direction={direction}
            helperText={helperText}
          >
            <Flex gap={8} align="center">
              <Switch
                {...props}
                {...field}
                checked={field.value}
                id={name}
                aria-required={
                  typeof registerOptions?.required === 'string' || typeof registerOptions?.required === 'boolean'
                    ? !!registerOptions?.required
                    : registerOptions?.required?.value
                }
                aria-invalid={!!error}
                aria-describedby={`${name}-error`}
              />
              {variant === 'text' && (
                <Typography as="span" type={TypographyType.BODY_M_REGULAR}>
                  {capitalize(formatMessage({ id: field.value ? 'switch.on' : 'switch.off' }))}
                </Typography>
              )}
            </Flex>
          </Control>
        );
      }}
    />
  );
}
