export enum InvoiceStatusEnum {
  CREATED = 'CREATED',
  PENDING_DEBTOR_APPROVAL = 'PENDING_DEBTOR_APPROVAL',
  SUBMITTED = 'SUBMITTED',
  PENDING_SIGN_OFF = 'PENDING_SIGN_OFF',
  OPEN = 'OPEN',
  FINALIZED = 'FINALIZED',
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  CLOSED = 'CLOSED',
  ON_HOLD = 'ON_HOLD',
  TO_BE_PAID = 'TO_BE_PAID',
}

export enum InvoiceTypesEnum {
  MERCHANT_DEBTOR = 'MERCHANT_DEBTOR',
  MERCHANT_MERCHANT = 'MERCHANT_MERCHANT',
  VENDOR_DEBTOR = 'VENDOR_DEBTOR',
  VENDOR_MERCHANT = 'VENDOR_MERCHANT',
  FACTOR_MERCHANT = 'FACTOR_MERCHANT',
  FACTOR_VENDOR = 'FACTOR_VENDOR',
  FACTOR_PRODUCT = 'FACTOR_PRODUCT',
  PRODUCT_VENDOR = 'PRODUCT_VENDOR',
  PRODUCT_MERCHANT = 'PRODUCT_MERCHANT',
  PRODUCT_DEBTOR = 'PRODUCT_DEBTOR',
}

export enum CollectiveInvoiceTypesEnum {
  COLLECTIVE_MERCHANT = 'COLLECTIVE_MERCHANT',
  COLLECTIVE_DEBTOR = 'COLLECTIVE_DEBTOR',
  COLLECTIVE_VENDOR = 'COLLECTIVE_VENDOR',
  COLLECTIVE_PRODUCT = 'COLLECTIVE_PRODUCT',
}

export enum PaymentStatusEnum {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  OPEN = 'OPEN',
}

export enum CollectiveInvoiceTypeEnum {
  COLLECTIVE_MERCHANT = 'COLLECTIVE_MERCHANT',
  COLLECTIVE_DEBTOR = 'COLLECTIVE_DEBTOR',
  COLLECTIVE_VENDOR = 'COLLECTIVE_VENDOR',
  COLLECTIVE_PRODUCT = 'COLLECTIVE_PRODUCT',
}

export enum INVOICE_FIELDS {
  DAYS_OPEN = 'daysOpen',
  FLAG = 'flag',
  STATUS = 'status',
  DUE_DATE = 'dueDate',
  ISSUE_DATE = 'issueDate',
  PAYMENT_TERM = 'paymentTerm',
  OUTSTANDING = 'outstanding',
  OUTSTANDING_TOTAL = 'outstandingTotal',
  RECEIVABLE = 'receivable',
  RECEIVABLE_TOTAL = 'receivableTotal',
  ISSUED_VIA = 'issuedVia',
  ISSUED_TO = 'issuedTo',
  ISSUER_NAME = 'issuerName',
  IDENTIFIER = 'identifier',
  INVOICE_TYPE = 'invoiceType',
  PRODUCT = 'product',
  AMOUNT_WITH_VAT = 'amountWithVat',
  DEPOT = 'depot',
  TRANSACTION_STATEMENT = 'transactionStatement',
  TRANSFER_STATEMENT = 'transferStatement',
  ISSUER_COLLECTIVE = 'issuerCollective',
  ISSUED_TO_COLLECTIVE = 'issuedToCollective',
}
