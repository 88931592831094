import { LocaleSwitcher, ILocaleSwitcherPops } from '@bootstrap/components/locale-switcher';
import DefaultDesktopLogo from '@ui/assets/img/finqle-logo-text.svg';
import { Flex } from '@ui/flex';
import { LiveChat } from '@ui/live-chat';
import { INoticeProps, Notice } from '@ui/notice';
import { Offset } from '@ui/offset';

import {
  OneTimePageContainer,
  OneTimePageLogo,
  OneTimePageLogoContainer,
  OneTimePageLocalSwitcherContainer,
} from './OneTimePage.styles';

export interface IOneTimePageProps extends INoticeProps {
  locales?: ILocaleSwitcherPops['locales'];
  onLocaleSwitch?: ILocaleSwitcherPops['onChange'];
  hasLiveChat?: boolean;
}

export const OneTimePage = ({ locales, onLocaleSwitch, hasLiveChat, ...noticeProps }: IOneTimePageProps) => {
  return (
    <OneTimePageContainer>
      <OneTimePageLogoContainer>
        <OneTimePageLogo src={DefaultDesktopLogo} alt="Logo" />
      </OneTimePageLogoContainer>
      <Flex direction="column" justify="center" align="center">
        <Notice {...noticeProps} />
        {hasLiveChat && (
          <Offset offsetTop={24}>
            <LiveChat />
          </Offset>
        )}
        {onLocaleSwitch && (
          <OneTimePageLocalSwitcherContainer>
            <LocaleSwitcher locales={locales} onChange={onLocaleSwitch} />
          </OneTimePageLocalSwitcherContainer>
        )}
      </Flex>
    </OneTimePageContainer>
  );
};
