import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { changeLimit } from '@bootstrap/utils/changeLimit';
import { DEFAULT_CURRENCY } from '@bootstrap/utils/formatCurrency';
import { track } from '@bootstrap/utils/mixpanel';
import { getStandardPaginationFromUrl } from '@bootstrap/utils/pagination';
import { captureException } from '@bootstrap/utils/sentry';
import MixpanelEventName from '@factoring/configs/mixpanel';
import { TableErrorCard } from '@factoring/shared/components/table-error-card';
import { FACTORING_DEFAULT_PAGE_SIZE } from '@factoring/shared/constants/constants';
import { InvoicesOrderByChoices, Ordering } from '@factoring/types/generated.enum';
import { useInvoicesQuery, useSubmitInvoiceMutation } from '@factoring/types/generated.hooks';
import { ShadowDefault } from '@hub/design-tokens';
import { Notice } from '@ui/notice';
import { Pagination } from '@ui/pagination';
import { Paper } from '@ui/paper';
import { ISelectedRowData, ITableColumn, SortDirection, Table } from '@ui/table';
import { TableBottom, TableTop } from '@ui/table/TableAddons';
import { toast } from '@ui/toast';

import { useInvoicesTableColumns } from './InvoicesTable.hooks';
import { InvoicesTableBulkActions } from './InvoicesTableBulkActions';
import InvoicesTableDialog from './InvoicesTableDialog';
import { InvoicesTableFilters } from './InvoicesTableFilters';
import { useInvoicesTableFilterContext } from './InvoicesTableFilters.context';

export const InvoicesTable = ({ issuerCollectiveId }: { issuerCollectiveId?: string }) => {
  const { formatMessage } = useIntl();
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Invoice[]>([]);
  const { searchParams, filters, getSearchParam, setSearchParams } = useInvoicesTableFilterContext();
  const pagination = getStandardPaginationFromUrl(searchParams, { limit: 20, offset: 0 });

  const [submitInvoice, { loading: isSubmitting }] = useSubmitInvoiceMutation();

  const onSelectedRowsChange = useCallback((data: ISelectedRowData<Invoice>) => {
    setSelectedRows(data.selectedRows);
  }, []);

  const field = (getSearchParam('field') as InvoicesOrderByChoices) || InvoicesOrderByChoices.Created;
  const direction = (getSearchParam('direction') as Ordering) || Ordering.Desc;
  const { data, error, loading, refetch } = useInvoicesQuery({
    variables: {
      filters: {
        ...filters,
        issuerCollectiveId: issuerCollectiveId,
      },
      pagination,
      ordering: {
        direction: direction,
        field: field,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const columns = useInvoicesTableColumns({ invoices: selectedRows });

  const onBulkAction = async () => {
    const invoiceIds = selectedRows.map((invoice) => invoice.uuid);
    for (const invoiceId of invoiceIds) {
      await submitInvoice({ variables: { uuid: invoiceId ?? '' } })
        .then(() => {
          setIsSubmitDialogOpen(false);
          track(MixpanelEventName.FACTORING_INVOICES_BULK_ACTION_SUBMIT);
          toast.success(<FormattedMessage id="invoices.invoiceSubmittedSuccessfully" />);
          setSelectedRows([]);
        })
        .catch((error) => {
          toast.error(<FormattedMessage id="error.genericRequestError" />);
          captureException(error);
        });
    }
    await refetch();
  };

  const onSort = (column: ITableColumn<Invoice>, sortDirection: SortDirection) => {
    searchParams.set('field', column.id);
    searchParams.set('direction', sortDirection);
    searchParams.delete('offset');
    setSearchParams(searchParams);
  };

  return (
    <>
      <Paper boxShadow={ShadowDefault}>
        <TableTop filters={<InvoicesTableFilters currency={data?.invoices.items[0]?.currency ?? DEFAULT_CURRENCY} />} />
        <Table
          isError={!!error}
          isLoading={loading}
          data={(data?.invoices.items ?? []) as Invoice[]}
          columns={columns}
          keyField="uuid"
          selectable
          onSelectedRowsChange={onSelectedRowsChange}
          onSort={onSort}
          autoClearRows={selectedRows.length === 0}
          noDataComponent={<Notice title={formatMessage({ id: 'error.noAvailableData' })} />}
          errorComponent={<TableErrorCard titleKey="error.oops" refetch={refetch} {...{ pagination }} />}
        />
        <TableBottom
          bulkActions={
            <InvoicesTableBulkActions
              onBulkAction={() => setIsSubmitDialogOpen(true)}
              count={selectedRows.length}
              selectedInvoices={selectedRows}
              isLoading={isSubmitting}
            />
          }
          pagination={
            !error ? (
              <Pagination
                onPageSizeChange={(limit: number) => changeLimit({ limit, searchParams, setSearchParams })}
                pageSize={FACTORING_DEFAULT_PAGE_SIZE}
                params={pagination}
                info={{ ...data?.invoices.pageInfo, hasPrevPage: pagination.offset > 0 }}
                onChangeOffset={(offset) => {
                  searchParams.set('offset', offset.toString());
                  setSearchParams(searchParams);
                }}
              />
            ) : undefined
          }
        />
      </Paper>

      <InvoicesTableDialog
        isOpen={isSubmitDialogOpen}
        onClose={() => {
          track(MixpanelEventName.FACTORING_INVOICES_BULK_ACTION_CANCEL);
          setIsSubmitDialogOpen(false);
        }}
        isLoading={isSubmitting}
        onSubmit={onBulkAction}
        selectedRows={selectedRows}
      />
    </>
  );
};
