import { AutoFocusInside } from 'react-focus-lock';
import { FormattedMessage } from 'react-intl';

import { Button } from '@ui/button';
import { Dialog } from '@ui/dialog';

export const TokenExpiredDialog = () => {
  return (
    <Dialog
      size="s"
      isOpen
      title={<FormattedMessage id="tokenExpiredDialog.title" />}
      description={<FormattedMessage id="tokenExpiredDialog.description" />}
      onClose={() => void 0}
      actions={[
        <AutoFocusInside key="1">
          <Button onClick={() => window.location.reload()}>
            <FormattedMessage id="action.refresh" />
          </Button>
        </AutoFocusInside>,
      ]}
    />
  );
};
