import { useRef, useEffect } from 'react';

export const useDidUpdate = (fn: () => void, inputs: unknown[]) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};
