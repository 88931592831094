import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@hub/auth-provider';

export const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  // Triggered once a logout page is hit
  useEffect(() => {
    // TODO: Use useApi to clean stored logo
    sessionStorage.removeItem('base64Logo');
    // This hack intends  to "reset" the routing to the index when user logs off
    navigate('/');
    setTimeout(logout);
  }, [logout, navigate]);

  return null;
};
