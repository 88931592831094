import { ChoiceChip } from '@ui/choice-chip/ChoiceChip';
import { ChoiceChipGroupStyles } from '@ui/choice-chip/ChoiceChipGroup.styles';
import { IChoiceChipGroup, IChoiceChipOption } from '@ui/choice-chip/ChoiceChipGroup.types';

export function ChoiceChipGroup<Value extends string>({ options, value, onChange }: IChoiceChipGroup<Value>) {
  const handleChoiceChanged = (option: IChoiceChipOption<Value>) => {
    onChange(option);
  };

  return (
    <ChoiceChipGroupStyles>
      {options.map((option, index) => {
        return (
          <ChoiceChip
            key={index}
            disabled={option.disabled}
            label={option.label}
            checked={value === option.value}
            onClick={() => {
              handleChoiceChanged(option);
            }}
          />
        );
      })}
    </ChoiceChipGroupStyles>
  );
}
