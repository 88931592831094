import { useRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { SwitchContainer, HiddenCheckbox, StateOn, StateContainer, StatePosition, StateOff } from './Switch.styles';
import { ISwitchProps } from './Switch.types';

export const Switch = forwardRef<HTMLInputElement, ISwitchProps>(
  ({ name, checked = false, onChange, onBlur, disabled, variant, size, ...ariaProps }, forwardedRef) => {
    const innerRef = useRef<HTMLInputElement | null>(null);
    const isTextMode = variant === 'text' && size !== 'small';

    return (
      <SwitchContainer>
        <HiddenCheckbox
          type="checkbox"
          role="switch"
          $size={size}
          $isTextMode={isTextMode}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          id={name}
          disabled={disabled}
          ref={(element) => {
            innerRef.current = element;
            if (typeof forwardedRef === 'function') {
              forwardedRef(element);
            } else if (forwardedRef) {
              forwardedRef.current = element;
            }
          }}
          {...ariaProps}
        />
        <StateContainer $size={size} $isTextMode={isTextMode}>
          {isTextMode && checked && (
            <StateOn aria-hidden="true">
              <FormattedMessage id="switch.on" />
            </StateOn>
          )}
          <StatePosition $size={size} />
          {isTextMode && !checked && (
            <StateOff aria-hidden="true">
              <FormattedMessage id="switch.off" />
            </StateOff>
          )}
        </StateContainer>
      </SwitchContainer>
    );
  },
);

Switch.displayName = 'Switch';
