import { useFormContext, Controller } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { CURRENCY_DECIMAL_SEPARATOR, CURRENCY_THOUSAND_SEPARATOR } from '@bootstrap/utils/formatCurrency';
import { Control } from '@ui/control';

import { BasicTextInput } from './BasicTextInput';
import { ITextInputProps } from './TextInput.types';

export interface ICurrencyInputProps extends Omit<ITextInputProps, 'isErrorShown'> {
  /**
   * @description
   * If defined it limits to given decimal scale
   */
  decimalScale?: NumericFormatProps['decimalScale'];
  /**
   * @default true
   */
  allowNegative?: NumericFormatProps['allowNegative'];
  /**
   * @description
   * Add a prefix before the number
   */
  prefix?: NumericFormatProps['prefix'];
  /**
   * @description
   * Add a prefix after the number
   */
  suffix?: NumericFormatProps['suffix'];
  /**
   * @description
   * Automatically sets prefix according to the currency.
   * This property overwrites prefix.
   */
  currency?: 'EUR' | 'GBP' | null;
}

const currencySigns = {
  EUR: '€',
  GBP: '£',
};

export function CurrencyInput({
  label,
  hidden,
  name,
  registerOptions,
  decimalScale,
  allowNegative,
  prefix,
  suffix,
  helperText,
  type,
  direction,
  currency,
  align,
  placeholder,
  ...props
}: ICurrencyInputProps) {
  const { control } = useFormContext();

  if (hidden) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState }) => {
        const { error } = fieldState;

        return (
          <Control
            label={label}
            error={error?.message}
            isErrorShown={!!error}
            name={name}
            helperText={helperText}
            direction={direction}
          >
            <NumericFormat
              {...props}
              placeholder={placeholder ?? (currency && currencySigns[currency]) ?? ''}
              value={value}
              getInputRef={ref}
              valueIsNumericString
              onValueChange={(values) => {
                onChange({ target: { value: values.value } });
              }}
              onBlur={onBlur}
              name={name}
              id={name}
              isErrorShown={!!error}
              prefix={currency ? `${currencySigns[currency]} ` : prefix ? `${prefix} ` : ''}
              suffix={suffix ? ` ${suffix}` : undefined}
              thousandSeparator={CURRENCY_THOUSAND_SEPARATOR}
              decimalSeparator={CURRENCY_DECIMAL_SEPARATOR}
              decimalScale={decimalScale ?? 2}
              allowNegative={allowNegative}
              customInput={BasicTextInput}
              aria-invalid={!!error}
              aria-required={!!registerOptions?.required}
              aria-describedby={`${name}-error`}
              align={align ?? 'end'}
            />
          </Control>
        );
      }}
    />
  );
}
