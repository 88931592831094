import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Logout } from '@bootstrap/components/Logout';
import { NotFoundPage } from '@bootstrap/components/NotFoundPage';
import { FEATURE_FLAGS } from '@factoring/configs/featureFlags';
import Dashboard from '@factoring/pages/Dashboard';
import { Offset } from '@ui/offset';

const AccountDetails = lazy(() => import('@factoring/pages/AccountDetailsPage'));
const AccountForm = lazy(() => import('@factoring/pages/AccountFormPage'));
const Accounts = lazy(() => import('@factoring/pages/AccountsPage'));
const CompanyProfile = lazy(() => import('@factoring/pages/CompanyProfilePage'));
const InvoiceDetails = lazy(() => import('@factoring/pages/InvoiceDetailsPage'));
const Invoices = lazy(() => import('@factoring/pages/InvoicesPage'));
const InvoiceUploadForm = lazy(() => import('@factoring/modules/invoice/invoice-upload-form/InvoiceUploadForm'));
const InvoiceCreateForm = lazy(() => import('@factoring/modules/invoice/invoice-create-form/InvoiceCreateForm'));
const Settlements = lazy(() => import('@factoring/pages/SettlementsPage'));
const StatementsDetails = lazy(() => import('@factoring/modules/statements/statement-details/StatementDetails'));
const StatementsTransactions = lazy(() => import('@factoring/pages/TransactionStatementsArchivePage'));
const TransactionStatementsArchive = lazy(() => import('@factoring/pages/TransactionStatementsArchivePage'));
const TransactionStatementsOverview = lazy(() => import('@factoring/pages/TransactionStatementsOverviewPage'));
const TransactionStatements = lazy(() => import('@factoring/pages/TransactionStatementsPage'));
const TransactionStatementDetails = lazy(() => import('@factoring/pages/TransactionStatementDetailsPage'));
const TransferStatementsPage = lazy(() => import('@factoring/pages/TransferStatementsPage'));
const TransferStatementDetails = lazy(() => import('@factoring/pages/TransferStatementDetailsPage'));
const UserDetails = lazy(() => import('@factoring/pages/UserDetailsPage'));
const Users = lazy(() => import('@factoring/pages/UsersPage'));

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/invoices">
        <Route index element={<Invoices />} />
        <Route path=":id" element={<InvoiceDetails />} />
        <Route path="upload" element={<InvoiceUploadForm />} />
        <Route path="upload/:id" element={<InvoiceUploadForm />} />
        <Route path="create" element={<InvoiceCreateForm />} />
        <Route path="edit/:id" element={<InvoiceCreateForm />} />
      </Route>
      <Route path="/settlements" element={<Settlements />} />
      <Route path="/accounts">
        <Route path="/accounts/:id" element={<AccountDetails />} />
        <Route path="new" element={<AccountForm />} />
        <Route index element={<Accounts />} />
      </Route>
      {FEATURE_FLAGS.TRANSACTION_STATEMENTS ? (
        <Route path="/transaction-statements">
          <Route path="/transaction-statements" element={<TransactionStatements />}>
            <Route index element={<TransactionStatementsOverview />} />
            <Route path="archived" element={<TransactionStatementsArchive />} />
          </Route>
          <Route path="/transaction-statements/:id/*" element={<TransactionStatementDetails />} />
          <Route path="/transaction-statements/archived/:id/*" element={<StatementsDetails />} />
        </Route>
      ) : (
        <Route path="/statements">
          <Route path="/statements/transactions" element={<StatementsTransactions />} />
          <Route path="/statements/transactions/:id" element={<StatementsDetails />} />
          <Route index element={<Navigate to="/statements/transactions" replace />} />
        </Route>
      )}
      {FEATURE_FLAGS.TRANSFER_STATEMENTS && (
        <Route path="/transfer-statements">
          <Route index element={<TransferStatementsPage />} />
          <Route path=":id/*" element={<TransferStatementDetails />} />
        </Route>
      )}
      <Route path="/settings">
        <Route path="profile" element={<CompanyProfile />} />
        <Route path="users" element={<Users />} />
        <Route path="users/:id" element={<UserDetails />} />
        <Route index element={<Navigate to="users" replace />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route
        path="*"
        element={
          <Offset offset={24}>
            <NotFoundPage />
          </Offset>
        }
      />
    </Routes>
  );
};
