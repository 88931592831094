import { INVOICE_STATUS_DEFAULT } from '@bootstrap/constants/invoices';
import { InvoiceStatusEnum } from '@bootstrap/types/invoices';
import { useClientInvoiceStatusOptions } from '@factoring/modules/invoice/Invoice.constants';
import { ClientInvoiceStatus } from '@factoring/modules/invoice/Invoice.types';
import { getClientInvoiceStatus } from '@factoring/modules/invoice/Invoice.utils';
import { Status, StatusType } from '@ui/status';

export const CLIENT_INVOICE_STATUS_TO_STATUS_TYPE: Record<ClientInvoiceStatus, StatusType> = {
  DRAFT: 'neutral',
  PENDING: 'warning',
  OPEN: 'informative',
  DISAPPROVED: 'error',
  PAID: 'success',
};

export const InvoiceStatus = ({
  status = INVOICE_STATUS_DEFAULT,
  paidOutDate,
}: {
  status: InvoiceStatus;
  paidOutDate?: Maybe<string>;
}) => {
  const clientInvoiceStatus = getClientInvoiceStatus(paidOutDate ? InvoiceStatusEnum.FINALIZED : status);
  const clientInvoiceStatusOptions = useClientInvoiceStatusOptions();
  const clientInvoiceStatusOption =
    clientInvoiceStatusOptions.find((option) => option.value === clientInvoiceStatus) || clientInvoiceStatusOptions[0];

  return (
    <Status type={CLIENT_INVOICE_STATUS_TO_STATUS_TYPE[clientInvoiceStatus]}>{clientInvoiceStatusOption.label}</Status>
  );
};
