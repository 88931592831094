import styled from 'styled-components';

import { ColorNeutral300, ColorWhite } from '@hub/design-tokens';

import { IPaperProps } from './Paper.types';

export const PaperStyles = styled.div<IPaperProps>`
  padding: ${({ offset }) =>
    typeof offset === 'number' ? `${offset}px` : offset?.map((p) => (p !== null ? `${p}px` : p)).join(' ')};
  padding-left: ${({ offsetLeft }) => offsetLeft && `${offsetLeft}px`};
  padding-right: ${({ offsetRight }) => offsetRight && `${offsetRight}px`};
  padding-top: ${({ offsetTop }) => offsetTop && `${offsetTop}px`};
  padding-bottom: ${({ offsetBottom }) => offsetBottom && `${offsetBottom}px`};
  border-radius: var(--border-radius-default);
  background-color: ${({ background = ColorWhite }) => background};
  border: ${({ hasBorder, borderColor = ColorNeutral300 }) => hasBorder && `1px solid ${borderColor}`};
  box-shadow: ${({ boxShadow = 'none' }) => boxShadow};
  overflow: ${({ overflow }) => overflow};
  max-width: ${({ maxWidth }) => maxWidth};
`;
