import styled, { css } from 'styled-components';

import { TypographyStyles } from '@ui/typography/Typography.styles';

export const ChoiceChipStyles = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: var(--color-neutral-800);
  border: 1px solid var(--color-neutral-300);
  border-radius: 200px;
  background: white;
  cursor: pointer;
  height: 40px;

  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--color-neutral-600);
          background: var(--color-neutral-300);
          cursor: default;

          & > ${TypographyStyles} {
            color: var(--color-neutral-600);
          }
        `
      : css`
          &:focus-visible {
            outline: auto;
            outline-offset: -1px;
            outline-color: var(--color-primary-700);
          }

          &:hover {
            background: var(--color-primary-100);
            border-color: var(--color-primary-300);
          }

          &:active {
            background: var(--color-primary-200);
            border-color: var(--color-primary-500);
          }

          &[aria-pressed='true'],
          &[aria-pressed='true']:hover {
            background: var(--color-primary-700);
            border-color: var(--color-primary-700);

            & > ${TypographyStyles} {
              color: white;
            }
          }
        `}
`;
