import { useFormContext, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OptionTypeBase } from 'react-select';

import { useDebouncedCallback } from '@bootstrap/hooks/useDebounce';
import { Control } from '@ui/control';
import { DropdownIndicator, ClearIndicator, MultiValueRemove } from '@ui/select/SelectComponents';

import { AutocompleteStyles } from './Autocomplete.styles';
import { IAutocompleteProps } from './Autocomplete.types';

export function Autocomplete<OptionType extends OptionTypeBase, IsMulti extends boolean>({
  name,
  registerOptions,
  label,
  helperText,
  direction,
  isMulti,
  isClearable,
  loadOptions,
  loadOptionsOnMount = true,
  noOptionsMessage = <FormattedMessage id="autocomplete.noOptionsMessage" />,
  startingMessage = <FormattedMessage id="autocomplete.startingMessage" />,
  ...props
}: IAutocompleteProps<OptionType, IsMulti>) {
  const { control, getValues } = useFormContext();
  const values = getValues(name);

  const debouncedLoadOptions = useDebouncedCallback((inputValue, callback) => {
    loadOptions(inputValue).then((options) => callback(options));
  }, 500);

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field, fieldState: { error } }) => {
        return (
          <Control
            isErrorShown={!!error}
            label={label}
            error={error?.message}
            name={name}
            direction={direction}
            helperText={helperText}
          >
            <AutocompleteStyles
              classNamePrefix="react-select"
              components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
              menuPlacement="auto"
              closeMenuOnSelect={!isMulti}
              isMulti={isMulti}
              isClearable={isMulti || isClearable}
              isErrorShown={!!error}
              hasValues={isMulti ? values?.length : !!values}
              inputId={name}
              aria-required={!!registerOptions?.required}
              aria-invalid={!!error}
              aria-describedby={`${name}-error`}
              noOptionsMessage={(value: { inputValue: string }) => {
                return value.inputValue.length === 0 ? startingMessage : noOptionsMessage;
              }}
              loadingMessage={() => <FormattedMessage id="select.loadingMessage" />}
              loadOptions={debouncedLoadOptions}
              defaultOptions={loadOptionsOnMount}
              cacheOptions
              {...field}
              {...props}
            />
          </Control>
        );
      }}
    />
  );
}
