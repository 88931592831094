import { useMemo, useState } from 'react';

import { IPaginationInfo } from '@ui/pagination';

const PAGE_LIMIT = 20;

export const useStatePagination = <D>(data: D[], limit: number = PAGE_LIMIT) => {
  const [offset, setOffset] = useState(0);
  const info: IPaginationInfo = useMemo(
    () => ({
      hasPrevPage: offset > 0,
      hasNextPage: data.length > offset + limit,
      totalCount: data.length,
    }),
    [offset, data.length, limit],
  );
  const params = useMemo(
    () => ({
      limit: limit,
      offset: offset,
    }),
    [limit, offset],
  );
  const paginatedData = useMemo(() => data.slice(offset, offset + limit), [data, offset, limit]);

  return {
    info,
    onChangeOffset: setOffset,
    params,
    data: paginatedData,
    limit,
  };
};
