import styled from 'styled-components';

import { IModalProps } from './Modal.types';

export const ModalContainerStyles = styled.div`
  @media (min-width: 769px) {
    isolation: isolate;
  }
`;

const desktopWidths = {
  s: 'calc(100vw / 3 * 1)',
  m: '50%',
  l: 'calc(100vw / 3 * 2)',
};

const tabletWidths = {
  s: '50%',
  m: 'calc(100vw / 3 * 2)',
  l: '90%',
};

const maxHeights = {
  s: '75%',
  m: '80%',
  l: '90%',
};

const maxWidths = {
  s: '850px',
  m: '1280px',
  l: '1700px',
};

export const ModalStyles = styled.div<IModalProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: fit-content;
  max-height: 100%;
  max-width: ${({ size }) => maxWidths[size]};
  overflow-y: auto;
  padding: 16px;
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  border-radius: var(--border-radius-default);
  z-index: var(--z-index-modal);

  @media (min-width: 769px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: ${({ size }) => maxHeights[size]};
    padding: 24px;
    z-index: 0;
  }

  @media (min-width: 769px) and (max-width: 1199px) {
    width: ${({ size }) => tabletWidths[size]};
  }

  @media (min-width: 1200px) {
    width: ${({ size }) => desktopWidths[size]};
  }
`;
