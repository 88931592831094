import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import { DashboardRecentInvoicesTable, DashboardStatementsTable } from '@factoring/modules/dashboard';
import { Offset } from '@ui/offset';
import { PageHeader } from '@ui/page-header';
import { Space } from '@ui/space';

const Dashboard = () => {
  return (
    <Offset offset={24}>
      <PageHeader title={<FormattedMessage id="field.dashboard" />} subtitle={`${dayjs().year()} Overview`} />
      <DashboardRecentInvoicesTable />
      <Space height={24} />
      <DashboardStatementsTable />
    </Offset>
  );
};

export default Dashboard;
