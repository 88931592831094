export const invoicesOrderingFieldMapping: Record<Lowercase<InvoicesOrderByChoices>, InvoicesOrderByChoices> = {
  amount: 'AMOUNT',
  amount_with_vat: 'AMOUNT_WITH_VAT',
  created: 'CREATED',
  credit_note: 'CREDIT_NOTE',
  currency: 'CURRENCY',
  direct_payment: 'DIRECT_PAYMENT',
  enable_direct_payment: 'ENABLE_DIRECT_PAYMENT',
  identifier: 'IDENTIFIER',
  invoice: 'INVOICE',
  invoice_type: 'INVOICE_TYPE',
  issue_date: 'ISSUE_DATE',
  is_uploaded: 'IS_UPLOADED',
  paid: 'PAID',
  payment_term: 'PAYMENT_TERM',
  status: 'STATUS',
  updated: 'UPDATED',
  uuid: 'UUID',
  week: 'WEEK',
  year: 'YEAR',
};
