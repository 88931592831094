import { ApolloError } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { BaseTemplate } from '@bootstrap/components/base-template';
import { LocaleSwitcher } from '@bootstrap/components/locale-switcher';
import { UserProfile } from '@bootstrap/components/UserProfile';
import { isProduction } from '@bootstrap/constants/env';
import { ProductsEnum } from '@bootstrap/constants/products';
import { RolesEnum } from '@bootstrap/constants/roles';
import { useMobile } from '@bootstrap/hooks/useMedia';
import { Locale } from '@bootstrap/types/locale';
import { captureException } from '@bootstrap/utils/sentry';
import { useGetMerchantCompanyNameQuery, useUpdateUserLocaleMutation } from '@factoring/types/generated.hooks';
import { useAuth } from '@hub/auth-provider';
import { ReactComponent as GroupeAdd } from '@ui/assets/icons/group_add.svg';
import { ReactComponent as Lock } from '@ui/assets/icons/lock.svg';
import { ReactComponent as Logout } from '@ui/assets/icons/logout.svg';
import { ReactComponent as OpenInNew } from '@ui/assets/icons/open-in-new.svg';
import { ReactComponent as Settings } from '@ui/assets/icons/settings.svg';
import { Button } from '@ui/button';
import { ContextMenu, ContextMenuItem } from '@ui/context-menu';
import { Divider } from '@ui/divider';
import { Popover } from '@ui/popover';
import { Sheet } from '@ui/sheet';
import { toast } from '@ui/toast';

import config from './constants';
import { useRoutes } from './routes';

interface IMainTemplateProps {
  children: ReactNode;
  desktopLogo: string;
  mobileLogo: string;
  isDefaultLogo: boolean;
}

export const MainTemplate = (props: IMainTemplateProps) => {
  const navigate = useNavigate();
  const routes = useRoutes();
  const isMobile = useMobile();
  const { tokenParsed } = useAuth();
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [updateLocale] = useUpdateUserLocaleMutation();
  const { data } = useGetMerchantCompanyNameQuery();

  const onCloseUserProfile = () => {
    setIsUserProfileOpen(false);
    setIsMobileMenuOpen(false);
  };

  const userName = tokenParsed?.name ?? '';
  const userEmail = tokenParsed?.email ?? '';
  const userCompanyName = data?.merchant?.company?.officialName ?? '';

  if (isProduction) {
    mixpanel.identify(userEmail);
    mixpanel.people.set({
      $email: userEmail,
      $name: userName,
      role: RolesEnum.MERCHANT,
      account: ProductsEnum.FACTORING,
      company: userCompanyName,
      locale: tokenParsed?.locale,
    });
  }

  const onLocaleSwitch = (locale: Locale) => {
    updateLocale({
      variables: {
        locale: locale,
      },
      onCompleted: () => {
        window.location.reload();
      },
      onError: (error: ApolloError) => {
        toast.error(error.message);
        captureException(error);
      },
    });
  };

  const ContextMenuContent = () => {
    return (
      <ContextMenu offset={isMobile ? 0 : 16}>
        <LocaleSwitcher onChange={onLocaleSwitch} />
        <Divider negativeMargin="-16px" />
        <ContextMenuItem
          label={<FormattedMessage id="companyProfile.title" />}
          onClick={() => {
            navigate('/settings/profile');
            onCloseUserProfile();
          }}
          iconLeft={<Settings />}
        />
        <ContextMenuItem
          label={<FormattedMessage id="field.users" />}
          onClick={() => {
            navigate('/settings/users');
            onCloseUserProfile();
          }}
          iconLeft={<GroupeAdd />}
        />
        <ContextMenuItem
          label={<FormattedMessage id="field.resetPassword" />}
          onClick={() => {
            window.open(config.RESET_PASSWORD_URL, '_blank');
            onCloseUserProfile();
          }}
          iconLeft={<Lock />}
          iconRight={<OpenInNew />}
        />
        <Divider negativeMargin="-16px" />
        <Button variant="secondary" onClick={() => navigate('/logout')} iconLeft={<Logout />} fullWidth>
          <FormattedMessage id="action.logout" />
        </Button>
      </ContextMenu>
    );
  };

  return (
    <BaseTemplate
      routes={routes}
      isLiveChatEnabled
      isMobileMenuOpen={isMobileMenuOpen}
      toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      userProfile={
        isMobile ? (
          <>
            <UserProfile
              userName={userName}
              userCompany={userCompanyName}
              onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
            />
            <Sheet
              isOpen={isUserProfileOpen}
              onClose={() => setIsUserProfileOpen(false)}
              title={userName}
              subtitle={userCompanyName}
            >
              <ContextMenuContent />
            </Sheet>
          </>
        ) : (
          <Popover
            transform={{ left: 8 }}
            onClickOutside={onCloseUserProfile}
            isOpen={isUserProfileOpen}
            positions={['top']}
            trigger={
              <UserProfile
                userName={userName}
                userCompany={userCompanyName}
                onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
              />
            }
            content={<ContextMenuContent />}
          />
        )
      }
      {...props}
    />
  );
};
