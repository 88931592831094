import { useRef, useEffect, forwardRef } from 'react';

import { useMobile } from '@bootstrap/hooks/useMedia';
import { ReactComponent as CheckIndeterminateSmall } from '@ui/assets/icons/check-indeterminate-small.svg';
import { ReactComponent as CheckSmall } from '@ui/assets/icons/check-small.svg';
import { ReactComponent as CheckboxIcon } from '@ui/assets/icons/checkbox.svg';
import { Typography, TypographyType } from '@ui/typography';

import { CheckboxLabel, HiddenCheckbox, CheckboxContainer } from './Checkbox.styles';
import { ICheckboxProps } from './Checkbox.types';

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ name, value, checked = false, label, onChange, onBlur, disabled, indeterminate }, forwardedRef) => {
    const innerRef = useRef<HTMLInputElement | null>(null);
    const isMobile = useMobile();

    useEffect(() => {
      const node = innerRef.current;
      if (node) {
        node.indeterminate = Boolean(indeterminate);
      }
    }, [indeterminate]);

    /**
     *  This fix will prevent raise click events for SVG children components.
     *  Only checkbox component should raise click event
     *  It prevents TableRowClick on selection change
     */
    const handleChildElementClick = (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
    };

    return (
      <CheckboxLabel disabled={disabled}>
        <HiddenCheckbox
          value={value}
          checked={checked}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          ref={(element) => {
            innerRef.current = element;
            if (typeof forwardedRef === 'function') {
              forwardedRef(element);
            } else if (forwardedRef) {
              forwardedRef.current = element;
            }
          }}
        />
        {/* data-type is used in Table component. */}
        <CheckboxContainer aria-hidden="true" data-type="checkbox">
          {checked && isMobile ? (
            <CheckboxIcon onClick={handleChildElementClick} />
          ) : checked && !isMobile ? (
            <CheckSmall onClick={handleChildElementClick} />
          ) : indeterminate ? (
            <CheckIndeterminateSmall onClick={handleChildElementClick} />
          ) : null}
        </CheckboxContainer>
        {label && (
          <Typography as="span" type={TypographyType.BODY_M_REGULAR}>
            {label}
          </Typography>
        )}
      </CheckboxLabel>
    );
  },
);

Checkbox.displayName = 'Checkbox';
