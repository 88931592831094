import { ColorNegative600, ColorNeutral800 } from '@hub/design-tokens';

export const getFontColor = (value: number | undefined): string | undefined => {
  // Handle the case where value is undefined
  if (value === undefined) {
    return undefined;
  }

  return value < 0 ? ColorNegative600 : ColorNeutral800;
};
