import { useIntl } from 'react-intl';

export const usePaymentTermOptions = () => {
  const { formatMessage } = useIntl();
  return [
    {
      value: 1,
      label: formatMessage({ id: 'message.daysX' }, { x: 1 }),
    },
    {
      value: 7,
      label: formatMessage({ id: 'message.daysX' }, { x: 7 }),
    },
    {
      value: 14,
      label: formatMessage({ id: 'message.daysX' }, { x: 14 }),
    },
    {
      value: 21,
      label: formatMessage({ id: 'message.daysX' }, { x: 21 }),
    },
    {
      value: 30,
      label: formatMessage({ id: 'message.daysX' }, { x: 30 }),
    },
    {
      value: 45,
      label: formatMessage({ id: 'message.daysX' }, { x: 45 }),
    },
    {
      value: 60,
      label: formatMessage({ id: 'message.daysX' }, { x: 60 }),
    },
    {
      value: 75,
      label: formatMessage({ id: 'message.daysX' }, { x: 75 }),
    },
    {
      value: 90,
      label: formatMessage({ id: 'message.daysX' }, { x: 90 }),
    },
  ];
};

export const DEFAULT_PAYMENT_TERM = 14;
