import { defaultPaginationParams, standardPaginationParams } from '@bootstrap/constants/pagination';
import { IStandardPaginationParamsType, IPaginationParamsType } from '@bootstrap/types/pagination';

export const getPaginationFromUrl = (
  queryParams: URLSearchParams,
  paginationParams?: Partial<IPaginationParamsType>,
): IPaginationParamsType => ({
  first: queryParams.has('limit')
    ? Number(queryParams.get('limit'))
    : paginationParams?.first ?? defaultPaginationParams.first,
  skip: queryParams.has('offset')
    ? Number(queryParams.get('offset'))
    : paginationParams?.skip ?? defaultPaginationParams.skip,
  orderBy: paginationParams?.orderBy ?? defaultPaginationParams.orderBy,
});

export const getStandardPaginationFromUrl = (
  queryParams: URLSearchParams,
  standardParams = standardPaginationParams,
): IStandardPaginationParamsType => ({
  limit: queryParams.has('limit') ? Number(queryParams.get('limit')) : standardParams.limit,
  offset: queryParams.has('offset') ? Number(queryParams.get('offset')) : standardParams.offset,
});
