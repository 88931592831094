import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { DISPLAY_DATE_FORMAT } from '@bootstrap/constants/date';
import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';
import { formatDate } from '@bootstrap/utils';
import { sum, avg } from '@bootstrap/utils/aggregations';
import { FormattedCurrency } from '@bootstrap/utils/formatCurrency';
import { getOutstandingDate } from '@bootstrap/utils/getOutstandingDate';
import { renderAmount } from '@bootstrap/utils/renderAmount';
import { getIdFromUUID } from '@bootstrap/utils/uuid';
import { InvoiceStatus } from '@factoring/modules/invoice/invoice-status';
import { Action } from '@ui/action';
import { ITableColumn } from '@ui/table';

import { InvoicesFilterField } from './InvoicesTableFilters.types';

export const useInvoicesTableColumns = ({
  isSortable = true,
  invoices,
}: {
  isSortable?: boolean;
  invoices?: Invoice[];
}): ITableColumn<Invoice>[] => {
  const { formatMessage } = useIntl();
  return [
    {
      id: 'IDENTIFIER',
      name: formatMessage({ id: 'field.invoiceNumber' }),
      cell: (row) => (
        <Action as={Link} to={row.pdf ? `/invoices/${row.uuid}` : `/invoices/edit/${row.uuid}`}>
          {row.invoiceNumber || getIdFromUUID(row.uuid)}
        </Action>
      ),
      sortable: isSortable,
    },
    {
      id: 'issuedTo',
      name: formatMessage({ id: 'field.issuedTo' }),
      cell: (row) => (
        <Action as={Link} to={`/accounts/${row.issuedToBranchId}`}>
          {row.issuedTo}
        </Action>
      ),
    },
    {
      id: 'ISSUE_DATE',
      name: formatMessage({ id: 'field.issueDate' }),
      cell: (row) => formatDate(row.issueDate),
      sortable: isSortable,
    },
    {
      id: 'PAID',
      name: formatMessage({ id: 'field.paid' }),
      cell: (row) => (row.paidIn ? dayjs(row.paidIn).format(DISPLAY_DATE_FORMAT) : EN_DASH),
      right: true,
      sortable: isSortable,
    },
    {
      id: 'transactionStatement',
      name: formatMessage({ id: 'invoices.transactionStatement' }),
      cell: (row) =>
        row.transactionStatement ? (
          <Action as={Link} to={`/transaction-statements/${row.transactionStatementUuid}`}>
            {row.transactionStatement}
          </Action>
        ) : (
          EN_DASH
        ),
      right: true,
    },
    {
      id: 'receivable',
      name: formatMessage({ id: 'field.receivable' }),
      cell: (row) => renderAmount({ amount: Number(row.receivableTotal), currency: row.currency }),
      right: true,
      Footer: invoices && <FormattedCurrency value={sum(invoices, 'receivableTotal')} />,
    },
    {
      id: 'AMOUNT_WITH_VAT',
      name: formatMessage({ id: 'field.totalAmount' }),
      cell: (row) => renderAmount({ amount: Number(row.amountWithVat), currency: row.currency }),
      right: true,
      sortable: isSortable,
      Footer: invoices && <FormattedCurrency value={sum(invoices, 'amountWithVat')} />,
    },
    {
      id: 'paymentTerm',
      name: formatMessage({ id: 'field.paymentTerm' }),
      cell: (row) => (row.paymentTerm ? formatMessage({ id: 'message.daysX' }, { x: row.paymentTerm }) : ''),
      right: true,
      Footer: invoices && <FormattedMessage id="message.daysX" values={{ x: avg(invoices, 'paymentTerm', true) }} />,
    },
    {
      id: 'daysOutstanding',
      name: formatMessage({ id: 'field.daysOutstanding' }),
      cell: (row) =>
        row.issueDate && row.paymentTerm
          ? formatMessage({ id: 'message.daysX' }, { x: getOutstandingDate(row.issueDate, row.paidIn ?? undefined) })
          : EN_DASH,
      right: true,
    },
    {
      id: 'STATUS',
      name: formatMessage({ id: 'field.status' }),
      cell: (row) => <InvoiceStatus status={row.status} paidOutDate={row.paid} />,
      right: true,
      sortable: isSortable,
    },
  ];
};

export const useFilterKeysTranslations = (branchName: string) => {
  return {
    [InvoicesFilterField.STATUS]: (value: string) => (
      <>
        <FormattedMessage id="field.status" />: {value}
      </>
    ),
    [InvoicesFilterField.INVOICE_NUMBER]: (value: string) => (
      <>
        <FormattedMessage id="field.invoiceNumber" />: {value}
      </>
    ),
    [InvoicesFilterField.ISSUED_TO_BRANCH_ID]: (value: string) => (
      <>
        <FormattedMessage id="field.issuedTo" />: {branchName ?? value}
      </>
    ),
    [InvoicesFilterField.ISSUE_DATE_FROM]: (value: string) => (
      <>
        <FormattedMessage id="field.issueDateFrom" />: {dayjs(value).format(DISPLAY_DATE_FORMAT)}
      </>
    ),
    [InvoicesFilterField.ISSUE_DATE_TO]: (value: string) => (
      <>
        <FormattedMessage id="field.issueDateTo" />: {dayjs(value).format(DISPLAY_DATE_FORMAT)}
      </>
    ),
    [InvoicesFilterField.OUTSTANDING_FROM]: (value: string) => (
      <>
        <FormattedMessage id="field.outstandingFrom" />: <FormattedCurrency value={parseFloat(value)} />
      </>
    ),
    [InvoicesFilterField.OUTSTANDING_TO]: (value: string) => (
      <>
        <FormattedMessage id="field.outstandingTo" />: <FormattedCurrency value={parseFloat(value)} />
      </>
    ),
    [InvoicesFilterField.RECEIVABLE_FROM]: (value: string) => (
      <>
        <FormattedMessage id="field.receivableFrom" />: <FormattedCurrency value={parseFloat(value)} />
      </>
    ),
    [InvoicesFilterField.RECEIVABLE_TO]: (value: string) => (
      <>
        <FormattedMessage id="field.receivableTo" />: <FormattedCurrency value={parseFloat(value)} />
      </>
    ),
  };
};
