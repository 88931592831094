import styled, { css } from 'styled-components';

import { ITextInputProps } from './TextInput.types';

const textStyles = css`
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::-webkit-date-and-time-value {
    text-align: left;
  }
`;

type InputStylesProps = Pick<ITextInputProps, 'accessoryLeft' | 'accessoryRight' | 'isErrorShown' | 'align'>;

export const InputStyles = styled.input<InputStylesProps>`
  ${textStyles}
  &[type="date"], &[type="time"] {
    appearance: none;
  }

  height: 40px;
  width: 100%;
  padding: 0 8px;
  padding-left: ${({ accessoryLeft }) => accessoryLeft && '40px'};
  padding-right: ${({ accessoryRight }) => accessoryRight && '40px'};
  margin: 0;
  color: var(--color-neutral-800);
  background: var(--color-white);
  border: 1px solid ${({ isErrorShown }) => (isErrorShown ? 'var(--color-negative-600)' : 'var(--color-neutral-300)')};
  border-radius: var(--border-radius-default);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: ${({ isErrorShown }) => !isErrorShown && '1px solid var(--color-neutral-500)'};
    }
  }

  &:focus {
    border: ${({ isErrorShown }) => !isErrorShown && '1px solid var(--color-primary-500)'};
  }

  &:disabled {
    color: var(--color-neutral-600);
    background: var(--color-neutral-100);
    border: 1px solid var(--color-neutral-300);
  }

  &::placeholder {
    ${textStyles}
    color: var(--color-neutral-600);
  }

  ${({ align }) =>
    align === 'end' &&
    css`
      text-align: end;
    `}
`;

const inputAccessoryStyles = css`
  position: absolute;
  color: var(--color-neutral-600);
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  pointer-events: none;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const InputContainerStyles = styled.div`
  position: relative;
`;

export const InputAccessoryLeft = styled.span`
  ${inputAccessoryStyles}
  left: 8px;
`;

export const InputAccessoryRight = styled.span`
  ${inputAccessoryStyles}
  right: 8px;
`;
