import styled, { CSSProperties, css, keyframes } from 'styled-components';

import { ColorNeutral100 } from '@hub/design-tokens';

import { TABLE_ROW_HEIGHT } from './Table.constants';

type CellProps = {
  right?: boolean;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  boxSizing?: CSSProperties['boxSizing'];
};

const placeholderAnimate = keyframes`
  0% { left: -60% }
  100% { left: 125% }
`;

const tableSkeletonStyles = (height: string, isLoading?: boolean) => {
  return css`
    position: relative;
    height: ${height};
    overflow-y: hidden;
    background-color: ${isLoading && 'var(--color-neutral-200)'};
    opacity: ${isLoading && '0.8'};
    isolation: isolate;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &::after {
      width: 60%;
      top: -30%;
      height: 160%;
      transform: rotate(5deg);
      display: block;
      content: '';
      position: absolute;
      left: -60%;
      opacity: 0.8;
      background: linear-gradient(270deg, ${ColorNeutral100}00 0%, ${ColorNeutral100} 50%, ${ColorNeutral100}00 100%);
      animation: ${placeholderAnimate} 1.5s linear infinite;
    }
  `;
};

export const TableOverflowWrapper = styled.div`
  padding: 16px;
  overflow-x: auto;
`;

export const TableStyles = styled.table`
  position: relative;
  border-spacing: 0;
  width: 100%;
  background-color: transparent;
`;

// 800px here is the height of 20 rows
export const TableLoaderStyles = styled.td<{ isLoading?: boolean; height?: string }>`
  ${({ isLoading, height = '800px' }) => isLoading && tableSkeletonStyles(height, true)}
`;

const basicRowStyles = css`
  height: ${TABLE_ROW_HEIGHT}px;
  width: 100%;

  > th:first-of-type,
  td:first-of-type {
    padding-inline-start: 8px;
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
  }

  > th:last-of-type,
  td:last-of-type {
    padding-inline-end: 8px;
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
  }
`;

export const TableHead = styled.thead`
  width: 100%;
`;

export const TableHeadRowStyles = styled.tr`
  ${basicRowStyles}
`;

export const TableRowStyles = styled.tr<{
  selected?: boolean;
  selectable: boolean;
  clickable?: boolean;
  active?: boolean;
  disableSelectedBackground?: boolean;
}>`
  ${basicRowStyles}
  cursor: ${({ clickable }) => clickable && 'pointer'};

  &:hover {
    background-color: var(--color-neutral-100);
  }

  ${({ selected, active, disableSelectedBackground }) =>
    !disableSelectedBackground &&
    (selected || active) &&
    css`
      background-color: var(--color-primary-100);

      &:hover {
        background-color: var(--color-primary-200);
      }
    `}
`;

const basicTableCellStyles = css<CellProps>`
  position: relative;
  text-align: ${({ right }) => (right ? 'end' : 'start')};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
      min-width: ${width};
      max-width: ${width};
    `};
  box-sizing: ${({ boxSizing }) => boxSizing};
  padding: 4px 8px;
  font-weight: 400;
  font-family: var(--typography-body);
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableHeadCellButton = styled.button<{ right?: boolean }>`
  display: inline-flex;
  align-items: center;
  flex-direction: ${({ right }) => (right ? 'row-reverse' : 'row')};
  gap: var(--border-radius-default);
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  background: inherit;
  cursor: pointer;
  vertical-align: middle;
`;

export const TableHeadCell = styled.th<CellProps>`
  ${basicTableCellStyles};
  font-weight: 700;
  color: var(--color-neutral-600);

  &[aria-sort='ascending'] > ${TableHeadCellButton}, &[aria-sort='descending'] > ${TableHeadCellButton} {
    color: var(--color-neutral-800);
  }

  > ${TableHeadCellButton}:hover {
    color: var(--color-neutral-700);
  }
`;

export const TableCellStyles = styled.td`
  ${basicTableCellStyles};
  color: var(--color-neutral-800);
`;

// Footer styles
export const TableFooterWrapper = styled.tfoot`
  width: 100%;
  background: var(--color-neutral-100);

  /* To add the space between tbody and tfoot */
  &::before {
    line-height: 8px;
    content: '.';
    color: transparent;
    background-color: var(--color-white);
    display: block;
  }
`;

export const TableFooterRowStyles = styled.tr`
  ${basicRowStyles}
  height: 48px;
  background: var(--color-neutral-100);
`;

export const TableFooterCellStyles = styled.td`
  ${basicTableCellStyles}
  font-weight: 700;
`;

// Expanded styles
export const ExpandedComponentWrapperTrStyles = styled.tr`
  ${basicRowStyles}
  height: auto;
`;
