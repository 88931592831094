import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { DEFAULT_LOCALE } from '@bootstrap/constants/locale';
import { flattenMessages } from '@bootstrap/utils/flattenMessages';
import { initializeGTM } from '@bootstrap/utils/gtm';
import { getLocaleFromLocalStorage, getLocaleFromNavigator } from '@bootstrap/utils/locale';
import { initializeMixpanel } from '@bootstrap/utils/mixpanel';
import { initializeSentry } from '@bootstrap/utils/sentry';

import { App } from './app';

async function loadLocaleData(locale?: string): Promise<Record<string, string>> {
  const [bootstrapMessages, mainMessages] = await Promise.all([
    import(`../../../libs/bootstrap/src/i18n/${locale}.json`),
    import(`./i18n/${locale}.json`),
  ]);

  return flattenMessages({
    ...bootstrapMessages.default,
    ...mainMessages.default,
  });
}

async function bootstrapApplication(locale = 'en', rootId = 'root') {
  initializeSentry(process.env.NX_PUBLIC_SENTRY_DSN || '');
  initializeMixpanel(process.env.NX_PUBLIC_MIXPANEL_ID || '');
  initializeGTM({
    gtmId: process.env.NX_PUBLIC_GTM_CODE || '',
  });
  const messages = await loadLocaleData(locale);
  const root = ReactDOM.createRoot(document.getElementById(rootId) as HTMLElement);
  root.render(
    <BrowserRouter>
      <App locale={locale} messages={messages} />
    </BrowserRouter>,
  );
}

void bootstrapApplication(getLocaleFromLocalStorage() ?? getLocaleFromNavigator() ?? DEFAULT_LOCALE);
