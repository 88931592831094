import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import md5 from 'md5';

import breakpoints from '@bootstrap/configs/breakpoints';
import { DISPLAY_DATE_FORMAT } from '@bootstrap/constants/date';
import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';

dayjs.extend(duration);

export const percentOf = (total: number, value: number): number => (total ? parseInt(`${(value / total) * 100}`) : 0);

const createFormData = (form: Record<string, string | File>): FormData =>
  Object.entries(form).reduce((formData, field) => {
    formData.append(...field);
    return formData;
  }, new FormData());

export const createAttachmentName = (name: string, uploadKey = ''): string => {
  const keyItems = uploadKey.split('/');
  const template = keyItems[keyItems.length - 1];
  return template.replace('${filename}', name);
};

export const getAttachmentNameFromUrl = (url: string): string => {
  if (!url) return url;

  const fileUrl = url.split('?')[0];
  const fileUrlParts = fileUrl.split('/');
  return decodeURIComponent(fileUrlParts[fileUrlParts.length - 1]);
};

/**
 * Replaces the `XXXXX_` prefix to the original filename
 * @param url string
 * @returns string
 */
export const getOriginalAttachmentNameFromUrl = (url: string): string =>
  getAttachmentNameFromUrl(url).replace(/^(.+?)_/i, '');

export const uploadFile = async (endpoint: string, form: Record<string, string | File>): Promise<void> => {
  const formData = createFormData(form);

  return fetch(endpoint, { method: 'post', body: formData }).then(async (res) => {
    if (res.status !== 200 && res.status !== 204) {
      const responseText = await res.text();
      const xml = new window.DOMParser().parseFromString(responseText, 'text/xml');
      const errorCode = xml.querySelector('Error > Code')?.textContent;
      throw new Error(errorCode || 'Upload file error');
    }
  });
};

export const noop = (): void => undefined;

export const formatDate = (dateString?: null | string, format = DISPLAY_DATE_FORMAT): string => {
  return dateString && dayjs(dateString).isValid() ? dayjs(dateString).format(format) : EN_DASH;
};

const hoursFormatter = new Intl.NumberFormat('nl-NL', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

export const formatHours = (hours = 0): string => hoursFormatter.format(hours);

export const getWeekNumber = (date = new Date()): number => {
  const firstJan = new Date(date.getFullYear(), 0, 1);
  const msInDay = 86400000;
  return Math.ceil(((date.getTime() - firstJan.getTime()) / msInDay + firstJan.getDay() + 1) / 7) - 1;
};

export const getGravatarUrl = (email = '', defaultAva = '', size = 32): string => {
  const hash = md5(email.trim().toLowerCase());
  const dSuffix = defaultAva ? `&d=${encodeURIComponent(defaultAva)}` : '';
  return `https://www.gravatar.com/avatar/${hash}?s=${size}&r=pg${dSuffix}`;
};

export const encodeBase64 = (svgString: string): string => {
  return `data:image/svg+xml;base64,${window.btoa(svgString)}`;
};

export const getIsPdfUrl = (url: string): boolean => /\.pdf/i.test(url.split('?')[0]);

export const getIsImageFileType = (mimeType: string): boolean => mimeType.split('/')[0] === 'image';

/**
 * @deprecated
 * Use `useMobile` hook from `@bootstrap/hooks/useMedia`
 */
export const isMobile = (currentBreakpoint: keyof typeof breakpoints): boolean =>
  breakpoints[currentBreakpoint] < breakpoints.sm;

export type IIntervalType = {
  start?: string | Dayjs;
  end?: string | Dayjs;
};

export const calculateDiff = (start: Dayjs, end: Dayjs): Dayjs => {
  const diff = start.second(0).diff(end.second(0));
  return dayjs().hour(0).minute(0).add(Math.abs(diff), 'milliseconds').second(0);
};

export const getIntervalMinutes = (interval: IIntervalType): number => {
  if (!interval?.start || !interval?.end) return 0;

  const diff = calculateDiff(dayjs(interval.start).second(0), dayjs(interval.end).second(0));
  return dayjs.duration({ minutes: diff.hour() * 60 + diff.minute() }).asMinutes() || 0;
};

export const sumIntervalsMinutes = (intervals: IIntervalType[]): number => {
  if (!Array.isArray(intervals)) return 0;
  return intervals.reduce((acc, interval) => acc + getIntervalMinutes(interval), 0);
};

export const sortVatSummary = (a: string, b: string) => {
  // 'deferred' should always come first
  if (a === 'deferred') return -1;
  if (b === 'deferred') return 1;

  // Convert numeric strings to numbers for comparison
  const numA = parseFloat(a);
  const numB = parseFloat(b);

  return numA - numB;
};

const formatMinutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return [hours, remainingMinutes].map((v) => `${v}`.padStart(2, '0')).join(':');
};

export const totalMinutesInHours = (minutes: number): string => (isNaN(minutes) ? '' : formatMinutesToTime(minutes));

/**
 * @deprecated
 * Replace with `sum` from `@bootstrap`.
 */
export const sumAmountWithVat = (lis: { amountWithVat: number }[]): number =>
  lis.reduce((acc, li) => acc + li.amountWithVat, 0) || 0;

/**
 * @deprecated
 * Replace with `sum` from `@bootstrap`.
 */
export const sumAmount = (lis: { amount: number }[]): number => lis.reduce((acc, li) => acc + li.amount, 0) || 0;


