import { FormattedMessage } from 'react-intl';

import { Button } from '@ui/button';
import { Flex } from '@ui/flex';

import { FilterChip } from './FilterChip';

export interface IFilterChipsGroupProps {
  filters: { label: string | JSX.Element; id: string; onDelete: () => void }[];
  onClearAll: () => void;
}

export const FilterChipsGroup = ({ filters, onClearAll }: IFilterChipsGroupProps) => {
  if (filters.length === 0) return null;

  return (
    <Flex gap={8} wrap="wrap" align="center">
      {filters.map(({ label, id, onDelete }) => {
        return <FilterChip key={id} label={label} onDelete={onDelete} />;
      })}
      <Button variant="secondary" onClick={onClearAll} size="small">
        <FormattedMessage id="action.clearAll" />
      </Button>
    </Flex>
  );
};
