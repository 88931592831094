export const changeLimit = ({
  searchParams,
  limit,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  limit: number;
  setSearchParams: (searchParams: URLSearchParams) => void;
}) => {
  searchParams.set('limit', limit.toString());
  setSearchParams(searchParams);
};
