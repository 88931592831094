import dayjs from 'dayjs';

import { DATE_DAY_UNIT_KEY } from '@bootstrap/constants/date';

/**
 * It's the same as days open for the invoice
 *
 * This will return date difference between issueDate and current date in the invoice still haven't paid
 * Otherwise, it will return difference between paid (paid_in) date and current date
 *
 * @param issueDateString - from that date the invoice should be paid until the payment term according to the contract
 * @param paidInDateString - the date when the invoices paid by the debtor
 */
export const getOutstandingDate = (issueDateString: string, paidInDateString?: string) => {
  const currentDate = dayjs();
  const issueDate = dayjs(issueDateString);

  if (paidInDateString) {
    const paidInDate = dayjs(paidInDateString);

    return paidInDate.diff(issueDate, DATE_DAY_UNIT_KEY);
  }

  return currentDate.diff(issueDate, DATE_DAY_UNIT_KEY);
};
