import styled, { css } from 'styled-components';

export const OneTimePageContainer = styled.main`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  padding: 80px 40px;
  justify-items: center;

  @media (max-width: 768px) {
    padding: 64px 16px;
    min-height: 100dvh;
  }
`;

export const OneTimePageLogoContainer = styled.div`
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }
`;

export const OneTimePageLogo = styled.img`
  max-width: 100%;
  height: 40px;
  object-fit: cover;
`;

export const OneTimePageLocalSwitcherContainer = styled.div`
  width: 140px;
  margin-top: 40px;
`;

const commonIconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  > svg {
    width: 32px;
    height: 32px;
  }
`;

export const FailureIcon = styled.div`
  ${commonIconStyles}
  background: var(--color-warning-200);

  > svg {
    color: var(--color-warning-700);
  }
`;

export const SuccessIcon = styled.div`
  ${commonIconStyles}
  background: var(--color-positive-200);

  > svg {
    color: var(--color-positive-500);
  }
`;
