import React from 'react';

import { ExpandedComponentWrapperTrStyles } from './Table.styles';

interface IExpandedComponentProps {
  children: React.ReactNode;
  colSpan: number;
}

export const ExpandedComponent = ({ children, colSpan }: IExpandedComponentProps) => {
  return (
    <ExpandedComponentWrapperTrStyles>
      <td colSpan={colSpan}>{children}</td>
    </ExpandedComponentWrapperTrStyles>
  );
};
