import { forwardRef } from 'react';
import FocusLock from 'react-focus-lock';

import { useKeydown } from '@bootstrap/hooks/useKeydown';
import { useLockBodyScroll } from '@bootstrap/hooks/useLockBodyScroll';
import { Backdrop } from '@ui/backdrop';
import { Portal } from '@ui/portal';

import { ModalContainerStyles, ModalStyles } from './Modal.styles';
import { IModalProps } from './Modal.types';

export const Modal = forwardRef<HTMLDivElement, IModalProps>(
  ({ isOpen, onClose, children, size, closeOnEscape = true, closeOnClickOutside = true, ...ariaProps }, ref) => {
    useLockBodyScroll(isOpen);

    useKeydown('Escape', closeOnEscape ? onClose : undefined);

    if (!isOpen) {
      return null;
    }

    return (
      <Portal>
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <FocusLock returnFocus autoFocus>
          <ModalContainerStyles>
            <Backdrop closeOnClickOutside={closeOnClickOutside} isOpen onClose={onClose} />
            <ModalStyles aria-modal="true" size={size} {...ariaProps} ref={ref}>
              {children}
            </ModalStyles>
          </ModalContainerStyles>
        </FocusLock>
      </Portal>
    );
  },
);

Modal.displayName = 'Modal';
