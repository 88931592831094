import styled from 'styled-components';

const SPACING_BETWEEN_ELEMENTS = '8px';
export const FieldSetContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: ${SPACING_BETWEEN_ELEMENTS};
  border: unset;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;

  & legend {
    margin-bottom: ${SPACING_BETWEEN_ELEMENTS};
    padding-inline: 0;
  }
`;
