import { FormProvider, useForm } from 'react-hook-form';

import { useFormFilterManager } from '@bootstrap/hooks';
import { Select, SelectOptionType } from '@ui/select';

import {
  IPageSizeProps,
  PAGE_SIZE_OPTIONS,
  PageSizeFormFields,
  PageSizeFormValues,
  PageSizeOption,
} from './PageSize.types';

export const PageSize = ({ value, onChange }: IPageSizeProps) => {
  const { getSearchParam } = useFormFilterManager();
  const pageSize = getSearchParam('limit') ?? value;

  const options: SelectOptionType[] = PAGE_SIZE_OPTIONS.map((option) => ({
    label: option.toString(),
    value: option.toString(),
  }));

  const values: PageSizeFormValues = {
    PAGE_SIZE: options.find((option) => option.value === pageSize.toString()) ?? null,
  };

  const methods = useForm<PageSizeFormValues>({
    defaultValues: values,
    values,
  });

  return (
    <FormProvider {...methods}>
      <form>
        <div style={{ width: '80px' }}>
          <Select
            name={PageSizeFormFields.PageSize}
            options={options}
            registerOptions={{
              onChange(event) {
                onChange(Number(event.target.value.value) as PageSizeOption);
              },
            }}
          />
        </div>
      </form>
    </FormProvider>
  );
};
