export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Decimal: { input: any; output: any };
  UUID: { input: any; output: any };
  Upload: { input: any; output: any };
  int: { input: any; output: any };
};

export type AmountRangeFilter = {
  maxAmount?: InputMaybe<Scalars['Decimal']['input']>;
  minAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

export type BranchFiltersInput = {
  /** Filter by branch number */
  branchNumber?: InputMaybe<Scalars['String']['input']>;
  /** Filter by company search ID number */
  companySearchId?: InputMaybe<Scalars['String']['input']>;
  /** Filter by contact email */
  contractContactEmail?: InputMaybe<Scalars['String']['input']>;
  /** Filter by contact person */
  contractContactName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by contract enabled status */
  contractEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by contract enabled status */
  contractStatus?: InputMaybe<ContractStatus>;
  /** Filter by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Filter by registration number */
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum BranchesOrderByChoices {
  BillingAddress = 'BILLING_ADDRESS',
  BranchNumber = 'BRANCH_NUMBER',
  Company = 'COMPANY',
  Created = 'CREATED',
  CreditAvailable = 'CREDIT_AVAILABLE',
  CreditLimit = 'CREDIT_LIMIT',
  CreditUsed = 'CREDIT_USED',
  Department = 'DEPARTMENT',
  Enabled = 'ENABLED',
  Id = 'ID',
  IsMainBranch = 'IS_MAIN_BRANCH',
  Name = 'NAME',
  RegisteredAddress = 'REGISTERED_ADDRESS',
  Updated = 'UPDATED',
}

export type BranchesOrderByChoicesQueryOrdering = {
  direction?: Ordering;
  /** Ordering field name */
  field: BranchesOrderByChoices;
};

export enum CollectiveInvoiceType {
  CollectiveDebtor = 'COLLECTIVE_DEBTOR',
  CollectiveMerchant = 'COLLECTIVE_MERCHANT',
}

export enum ContractStatus {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  PreApproved = 'PRE_APPROVED',
  Terminated = 'TERMINATED',
  Unset = 'UNSET',
  Upcoming = 'UPCOMING',
}

export enum Country {
  Be = 'BE',
  De = 'DE',
  Fr = 'FR',
  Gb = 'GB',
  Nl = 'NL',
}

export enum CountryCodes {
  At = 'AT',
  Be = 'BE',
  Bg = 'BG',
  Ch = 'CH',
  De = 'DE',
  Dk = 'DK',
  Es = 'ES',
  Fr = 'FR',
  Gb = 'GB',
  It = 'IT',
  Li = 'LI',
  Lv = 'LV',
  Nl = 'NL',
  No = 'NO',
  Pl = 'PL',
  Pt = 'PT',
  Ro = 'RO',
  Se = 'SE',
  Ua = 'UA',
  Uk = 'UK',
}

export type CreateDebtorInput = {
  accountsPayableEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  bankAccountCode?: InputMaybe<Scalars['String']['input']>;
  bankAccountName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  /** Company ID from the companies search results */
  companySearchId: Scalars['String']['input'];
  contactEmail: Scalars['String']['input'];
  contactMobile?: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  contactPhone: Scalars['String']['input'];
  creditLimitRequested?: Scalars['Decimal']['input'];
  defaultDepot?: Scalars['Decimal']['input'];
  defaultGsplit?: Scalars['Decimal']['input'];
  invoicingEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentTerm: Scalars['int']['input'];
  registrationNumber: Scalars['String']['input'];
  signedContract: SignedContract;
  /** Company VAT Number */
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceInput = {
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  /** Assign the debtor branch ID of this invoice */
  debtorBranchId: Scalars['UUID']['input'];
  directPaymentAmount: Scalars['Decimal']['input'];
  /** Invoice number */
  identifier: Scalars['String']['input'];
  /** Issue date */
  issueDate?: Scalars['Date']['input'];
  /** PO number */
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Project Name */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** Invoice reference/subject */
  reference?: InputMaybe<Scalars['String']['input']>;
  unitLineItems?: InputMaybe<Array<CreateUnitLineItemInput>>;
};

export type CreateUnitLineItemInput = {
  description: Scalars['String']['input'];
  /** The invoice for this line item */
  invoiceUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The quantity or the number of units */
  quantity: Scalars['Decimal']['input'];
  tariff: Scalars['Decimal']['input'];
  vat: Scalars['Decimal']['input'];
};

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
}

export type DateRangeFilter = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type DebtorsFiltersInput = {
  /** Filter by account name */
  accountName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by contact person name */
  contactPersonName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by enabled or disabled companies */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by company name */
  officialName?: InputMaybe<Scalars['String']['input']>;
};

export type GetDebtorsInput = {
  filters?: InputMaybe<DebtorsFiltersInput>;
  pagination?: InputMaybe<PageInput>;
};

export type InvoiceFilters = {
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceType: InvoiceType;
  issueDate?: InputMaybe<DateRangeFilter>;
  issuedToBranchId?: InputMaybe<Scalars['UUID']['input']>;
  issuerCollectiveId?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<InvoiceStatus>;
  statuses?: InputMaybe<Array<InputMaybe<InvoiceStatus>>>;
};

export enum InvoiceStatus {
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Disapproved = 'DISAPPROVED',
  Finalized = 'FINALIZED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
  PendingDebtorApproval = 'PENDING_DEBTOR_APPROVAL',
  Submitted = 'SUBMITTED',
  ToBePaid = 'TO_BE_PAID',
}

export enum InvoiceType {
  FactorMerchant = 'FACTOR_MERCHANT',
  MerchantDebtor = 'MERCHANT_DEBTOR',
}

export type InvoicesArgs = {
  filters: InvoiceFilters;
  ordering?: InputMaybe<InvoicesOrderByChoicesQueryOrdering>;
  pagination?: InputMaybe<Pagination>;
};

export enum InvoicesOrderByChoices {
  Amount = 'AMOUNT',
  AmountWithVat = 'AMOUNT_WITH_VAT',
  Created = 'CREATED',
  CreditNote = 'CREDIT_NOTE',
  Currency = 'CURRENCY',
  DirectPayment = 'DIRECT_PAYMENT',
  EnableDirectPayment = 'ENABLE_DIRECT_PAYMENT',
  Identifier = 'IDENTIFIER',
  Invoice = 'INVOICE',
  InvoiceType = 'INVOICE_TYPE',
  IssueDate = 'ISSUE_DATE',
  IsUploaded = 'IS_UPLOADED',
  Paid = 'PAID',
  PaymentTerm = 'PAYMENT_TERM',
  Status = 'STATUS',
  Updated = 'UPDATED',
  Uuid = 'UUID',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type InvoicesOrderByChoicesQueryOrdering = {
  direction?: Ordering;
  /** Ordering field name */
  field: InvoicesOrderByChoices;
};

export type MerchantUserInput = {
  /** Merchant user email address */
  email: Scalars['String']['input'];
  /** Merchant user first name */
  firstName: Scalars['String']['input'];
  /** Merchant user last name */
  lastName: Scalars['String']['input'];
  /** Merchant user mobile */
  mobile: Scalars['String']['input'];
  /** Merchant user phone */
  phone: Scalars['String']['input'];
  /** Merchant user salutation */
  salutation?: InputMaybe<SalutationTypeChoices>;
};

export type OffsetPaginationInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export enum Ordering {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type PageInput = {
  first?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
};

export type Pagination = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type PaymentTransactionInput = {
  cancelUrl: Scalars['String']['input'];
  description: Scalars['String']['input'];
  /** String type is deprecated. Use UUID instead */
  invoices: Array<Scalars['String']['input']>;
  successUrl: Scalars['String']['input'];
};

export enum Product {
  Connect = 'CONNECT',
  Factoring = 'FACTORING',
  PayForPlatforms = 'PAY_FOR_PLATFORMS',
}

export enum SalutationTypeChoices {
  Mevr = 'Mevr',
  Mr = 'Mr',
  X = 'X',
}

export type SearchInput = {
  /** Search for a company using the company name or reg number. */
  company: Scalars['String']['input'];
  /** This field is deprecated and will be removed soon. Please use countryCode instead. Search from a specific country using the country code. */
  countries?: InputMaybe<Scalars['String']['input']>;
  /** Search from a specific country using the country code. */
  countryCode?: InputMaybe<CountryCodes>;
  pageSize?: Scalars['Int']['input'];
};

export type SettlementFilters = {
  description?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['UUID']['input']>;
  parentSettlementId?: InputMaybe<Scalars['UUID']['input']>;
  settlementType?: InputMaybe<SettlementType>;
  status?: InputMaybe<SettlementStatus>;
  transactionStatementId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum SettlementOrderByChoices {
  Amount = 'AMOUNT',
  Created = 'CREATED',
  Currency = 'CURRENCY',
  Description = 'DESCRIPTION',
  Invoice = 'INVOICE',
  Settlement = 'SETTLEMENT',
  SettlementType = 'SETTLEMENT_TYPE',
  Status = 'STATUS',
  Updated = 'UPDATED',
}

export type SettlementOrderByChoicesQueryOrdering = {
  direction?: Ordering;
  /** Ordering field name */
  field: SettlementOrderByChoices;
};

export enum SettlementStatus {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Opened = 'OPENED',
  Settled = 'SETTLED',
  Split = 'SPLIT',
}

export enum SettlementType {
  Advance = 'ADVANCE',
  AdvancePayback = 'ADVANCE_PAYBACK',
  BadDebt = 'BAD_DEBT',
  BankTransaction = 'BANK_TRANSACTION',
  Correction = 'CORRECTION',
  Custom = 'CUSTOM',
  DebtPayable = 'DEBT_PAYABLE',
  Depot = 'DEPOT',
  DepotPayable = 'DEPOT_PAYABLE',
  PspTransaction = 'PSP_TRANSACTION',
  Settle = 'SETTLE',
  Suspense = 'SUSPENSE',
  UsedDepot = 'USED_DEPOT',
}

export type SettlementsArgs = {
  filters?: InputMaybe<SettlementFilters>;
  ordering?: InputMaybe<SettlementOrderByChoicesQueryOrdering>;
  pagination?: InputMaybe<Pagination>;
};

export type SignedContract = {
  /** Signed contract */
  contractPdf?: InputMaybe<Scalars['Upload']['input']>;
  /** Debtor contract signing date */
  contractSigningDate?: InputMaybe<Scalars['Date']['input']>;
};

export type StatementsArgs = {
  filter?: InputMaybe<StatementsFilters>;
  /** Number of items to return */
  first?: Scalars['Int']['input'];
  /** Order (sort) items */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Number of items to skip */
  skip?: Scalars['Int']['input'];
};

export type StatementsFilters = {
  /** Filter by Absolute Total Amount range (optional) */
  absAmountWithVat?: InputMaybe<AmountRangeFilter>;
  /** Filter by Total Amount range (optional) */
  amountWithVat?: InputMaybe<AmountRangeFilter>;
  /** Filter by Issue Date range (optional) */
  issueDate?: InputMaybe<DateRangeFilter>;
};

export type TransactionStatementFilters = {
  collectiveInvoiceIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  issuedToCompanyIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  issuedViaCompanyIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  totalFrom?: InputMaybe<Scalars['Decimal']['input']>;
  totalTo?: InputMaybe<Scalars['Decimal']['input']>;
  uuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export enum TransactionStatementOrderByChoices {
  Amount = 'AMOUNT',
  Created = 'CREATED',
  Identifier = 'IDENTIFIER',
  TransactionDate = 'TRANSACTION_DATE',
  Updated = 'UPDATED',
}

export type TransactionStatementOrderByChoicesQueryOrdering = {
  direction?: Ordering;
  /** Ordering field name */
  field: TransactionStatementOrderByChoices;
};

export enum TransactionStatementStatus {
  Created = 'CREATED',
  Paid = 'PAID',
}

export type TransactionStatementsArgs = {
  filters?: InputMaybe<TransactionStatementFilters>;
  ordering?: InputMaybe<TransactionStatementOrderByChoicesQueryOrdering>;
  pagination?: InputMaybe<Pagination>;
};

export enum TransferStatementOrderByChoices {
  Created = 'CREATED',
  Identifier = 'IDENTIFIER',
  SentOn = 'SENT_ON',
  Updated = 'UPDATED',
}

export type TransferStatementOrderByChoicesQueryOrdering = {
  direction?: Ordering;
  /** Ordering field name */
  field: TransferStatementOrderByChoices;
};

export type TransferStatementsArgs = {
  filters?: InputMaybe<TransferStatementsFilters>;
  ordering?: InputMaybe<TransferStatementOrderByChoicesQueryOrdering>;
  pagination?: InputMaybe<Pagination>;
};

export type TransferStatementsFilters = {
  createdDateFrom?: InputMaybe<Scalars['Date']['input']>;
  createdDateTo?: InputMaybe<Scalars['Date']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreatedInvoiceInput = {
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  /** Assign the debtor branch ID of this invoice */
  debtorBranchId: Scalars['UUID']['input'];
  directPaymentAmount: Scalars['Decimal']['input'];
  /** Invoice number */
  identifier: Scalars['String']['input'];
  /** Issue date */
  issueDate?: Scalars['Date']['input'];
  /** PO number */
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Project Name */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** Invoice reference/subject */
  reference?: InputMaybe<Scalars['String']['input']>;
  unitLineItems?: InputMaybe<Array<CreateUnitLineItemInput>>;
  /** Invoice UUID */
  uuid: Scalars['UUID']['input'];
};

export type UpdateInvoiceInput = {
  amount: Scalars['Decimal']['input'];
  amountWithVat: Scalars['Decimal']['input'];
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  /** Assign the debtor branch ID of this invoice */
  debtorBranchId: Scalars['UUID']['input'];
  directPaymentAmount: Scalars['Decimal']['input'];
  identifier: Scalars['String']['input'];
  /** Issue date */
  issueDate?: Scalars['Date']['input'];
  pdf?: InputMaybe<Scalars['Upload']['input']>;
  /** PO number */
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Project Name */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** Invoice reference/subject */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Invoice UUID */
  uuid: Scalars['UUID']['input'];
};

export type UpdateUnitLineItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** The quantity or the number of units */
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  tariff?: InputMaybe<Scalars['Decimal']['input']>;
  /** The UUID of the unit line item to update */
  uuid: Scalars['UUID']['input'];
  vat?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UploadInvoiceInput = {
  amount: Scalars['Decimal']['input'];
  amountWithVat: Scalars['Decimal']['input'];
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  /** Assign the debtor branch ID of this invoice */
  debtorBranchId: Scalars['UUID']['input'];
  directPaymentAmount: Scalars['Decimal']['input'];
  identifier: Scalars['String']['input'];
  /** Issue date */
  issueDate?: Scalars['Date']['input'];
  pdf: Scalars['Upload']['input'];
  /** PO number */
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Project Name */
  projectName?: InputMaybe<Scalars['String']['input']>;
  /** Invoice reference/subject */
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type VatRateFilters = {
  country?: InputMaybe<Country>;
};

export enum VatRateLevel {
  Deferred = 'DEFERRED',
  Reduced_1 = 'REDUCED_1',
  Reduced_2 = 'REDUCED_2',
  Reduced_3 = 'REDUCED_3',
  Standard = 'STANDARD',
  Zero = 'ZERO',
}
