import { OperationVariables, useQuery } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { OperationDefinitionNode } from 'graphql/language';
import { FieldNode } from 'graphql/language/ast';
import { Kind } from 'graphql/language/kinds';

import { IPaginationInfo } from '@ui/pagination';

/**
 * Interface for match paginated result data of query document.
 */
export interface IPaginatedType {
  pageInfo: IPaginationInfo;
}

/**
 * __useLoadMoreQuery__
 *
 * This is a generic for query hooks with nextPage and fetchMore functionality that can be used for mobile components
 *    when they loads the next portion of data on scrolling.
 *
 * type TQuery - Type of query from typed hook
 * type TQueryVariables - Type of variables from typed hook
 *
 * @param document DocumentNode that that equals to typed query used in typed hook;
 * @param itemsNodeName Item property name for retrieving items from result data as depending on typed data item property could have its own name;
 * @param variables query variables that will be passed into the query;
 * @param skip skip flag;
 *
 * @example
 * const { data, info, error, loading, refetch, hasMore, nextPage, } = useLoadMoreQuery<InvoicesListQuery, InvoicesListQueryVariables>({
 *   document: InvoiceListDocument,
 *   itemsNodeName: 'items', // Name of items node, example data.invoices.items, here itemsNodeName is 'items'
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 *   skip: true
 * });
 */
export const useLoadMoreQuery = <TQuery, TQueryVariables extends OperationVariables>({
  document,
  // dataNodeName,
  itemsNodeName,
  variables,
  skip,
}: {
  document: TypedDocumentNode<TQuery, TQueryVariables>;
  // dataNodeName: string;
  itemsNodeName: string;
  variables?: TQueryVariables;
  skip?: boolean;
}) => {
  /**
   * Here we get operation definition of parsed GQL document.
   * This required to define a proper name of node that should contain results in response data
   */
  // Parsed operation definition of document
  const operationDefinition = document.definitions.find(
    (definition) => definition.kind === Kind.OPERATION_DEFINITION,
  ) as OperationDefinitionNode;

  /**
   * Node name of document which should contains results in response data.
   * Path for getting proper value defined via experimental way.
   */
  const dataNodeName = (
    operationDefinition.selectionSet.selections.find((selection) => selection.kind === Kind.FIELD) as FieldNode
  ).name.value;

  /**
   * Define type of paginated data type in document.
   * This requires for further proper keys typings.
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  type PaginatedDataType = TQuery[dataNodeName];

  /**
   * Define type of item result data type in document.
   * This equals the typed item in typed hook.
   * This requires for further proper keys typings.
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  type ItemType = PaginatedDataType[itemsNodeName];

  const { data, error, loading, refetch, fetchMore } = useQuery<TQuery, TQueryVariables>(document, {
    variables,
    skip,
  });

  // Items from query result according to document and defined itemsNodeName
  const items: ItemType[] = (
    data && data[dataNodeName as keyof TQuery]
      ? data[dataNodeName as keyof TQuery][itemsNodeName as keyof PaginatedDataType]
      : []
  ) as ItemType[];

  // Pagination info from query result according to document and defined itemsNodeName
  const info: IPaginationInfo = (
    data && data[dataNodeName as keyof TQuery] ? (data[dataNodeName as keyof TQuery] as IPaginatedType).pageInfo : {}
  ) as IPaginationInfo;

  const hasMore = info.hasNextPage;
  const nextPage = async () => {
    if (!hasMore) return;

    await fetchMore({
      variables: {
        skip: items.length,
      },
      updateQuery: (prev: TQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        // Return the same data type structure as expected in TQuery according to document and defined dataNodeName
        return {
          [dataNodeName as keyof TQuery]: {
            pageInfo: (fetchMoreResult[dataNodeName as keyof TQuery] as IPaginatedType).pageInfo,
            [itemsNodeName as keyof PaginatedDataType]: [
              ...(prev[dataNodeName as keyof TQuery][itemsNodeName as keyof PaginatedDataType] as ItemType[]),
              ...(fetchMoreResult[dataNodeName as keyof TQuery][
                itemsNodeName as keyof PaginatedDataType
              ] as ItemType[]),
            ],
          },
        } as TQuery;
      },
    });
  };

  return {
    data: items,
    info,
    error,
    loading,
    refetch,
    hasMore,
    nextPage,
  };
};
