import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useMobile } from '@bootstrap/hooks/useMedia';
import { ColorNeutral600 } from '@hub/design-tokens';
import { Action } from '@ui/action';
import { ReactComponent as ArrowBack } from '@ui/assets/icons/arrow-back.svg';
import { Flex } from '@ui/flex';
import { Offset } from '@ui/offset';
import { Typography, TypographyType } from '@ui/typography';

export interface IPageHeaderProps {
  title?: string | JSX.Element;
  status?: JSX.Element;
  addon?: React.ReactNode;
  subtitle?: string | JSX.Element;
  hasBackButton?: boolean;
}

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Action as="button" onClick={() => navigate(-1)} icon={<ArrowBack />}>
      <FormattedMessage id="action.back" />
    </Action>
  );
};

export const PageHeader = ({ title, status, addon, subtitle, hasBackButton }: IPageHeaderProps) => {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <Offset offsetBottom={32}>
        {hasBackButton && (
          <Offset offsetBottom={32}>
            <BackButton />
          </Offset>
        )}
        <Flex data-testid="page-header" gap={32} direction="column" align="start">
          {(title || subtitle || status) && (
            <Flex align="start" gap={16} direction="column-reverse">
              {(title || subtitle) && (
                <div>
                  {title && (
                    <Typography as="h1" type={TypographyType.HEADING_M}>
                      {title}
                    </Typography>
                  )}
                  {subtitle && (
                    <Typography type={TypographyType.HEADING_S} color={ColorNeutral600} offsetTop={8}>
                      {subtitle}
                    </Typography>
                  )}
                </div>
              )}
              {status}
            </Flex>
          )}
          {addon}
        </Flex>
      </Offset>
    );
  }

  return (
    <Offset offsetBottom={20}>
      {hasBackButton && (
        <Offset offsetBottom={16}>
          <BackButton />
        </Offset>
      )}
      <Flex data-testid="page-header" justify="space-between" align="center" gap={16}>
        <Flex align="center" gap={16}>
          <Typography as="h1" type={TypographyType.HEADING_L}>
            {title}
          </Typography>
          {status}
        </Flex>
        {addon}
      </Flex>
      {subtitle && (
        <Typography type={TypographyType.HEADING_S} color={ColorNeutral600} offsetTop={8}>
          {subtitle}
        </Typography>
      )}
    </Offset>
  );
};
