import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

export interface IActionProps<T = HTMLAnchorElement>
  extends Partial<Pick<LinkProps, 'reloadDocument' | 'to' | 'replace' | 'state'>>,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
  icon?: React.ReactElement;
  /**
   * @default a
   */
  as?: keyof JSX.IntrinsicElements | React.ComponentType<T>;
  disabled?: boolean;
  fade?: boolean;
}

const ActionStyles = styled.a<{ disabled?: boolean; fade?: boolean }>`
  display: inline-block;
  appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
  color: var(--color-primary-500);
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--color-primary-600);
    }
  }

  &:active {
    color: var(--color-primary-700);
  }

  &:disabled {
    color: var(--color-neutral-600);
    cursor: default;
  }

  &:focus-visible {
    outline: auto;
  }

  ${({ fade }) =>
    fade &&
    css`
      color: var(--color-neutral-200);

      //&:hover {
      //  color: var(--color-primary-600);
      //}
    `}
`;

const ActionContentStyles = styled.span`
  display: inline-flex;
  gap: 8px;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

export function Action<T>({ children, as = 'a', icon, disabled, fade, ...props }: IActionProps<T>) {
  return (
    <ActionStyles as={as} disabled={disabled} fade={fade} {...props}>
      {icon ? (
        <ActionContentStyles>
          {icon}
          {children}
        </ActionContentStyles>
      ) : (
        children
      )}
    </ActionStyles>
  );
}
