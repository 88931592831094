import { useId } from 'react';

import { Flex } from '@ui/flex';
import { Modal } from '@ui/modal';
import { Offset } from '@ui/offset';
import { Typography, TypographyType } from '@ui/typography';

import { IDialogProps } from './Dialog.types';

export const Dialog = ({ isOpen, title, size, description, onClose, actions }: IDialogProps) => {
  const id = useId();
  const ariaDescribedBy = description ? `${id}-description` : undefined;

  return (
    <Modal
      role="alertdialog"
      size={size}
      isOpen={isOpen}
      aria-labelledby={id}
      aria-describedby={ariaDescribedBy}
      onClose={onClose}
    >
      <Offset offsetBottom={8}>
        <Typography as="h3" type={TypographyType.HEADING_M} id={id}>
          {title}
        </Typography>
      </Offset>
      {description && (
        <Typography type={TypographyType.BODY_M_REGULAR} id={ariaDescribedBy} as="div">
          {description}
        </Typography>
      )}
      <Offset offsetTop={24}>
        <Flex gap={16} justify="end">
          {actions}
        </Flex>
      </Offset>
    </Modal>
  );
};
