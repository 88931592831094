import styled from 'styled-components';

export const FilterChipStyles = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px;
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-200);

  &:focus-visible {
    outline: auto;
    outline-offset: -1px;
    outline-color: var(--color-primary-700);
  }
`;

export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: var(--border-radius-default);

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: var(--color-neutral-300);
  }

  &:active {
    background: var(--color-neutral-400);
  }
`;
