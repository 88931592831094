import {
  CollectiveInvoiceTypesEnum,
  InvoiceStatusEnum,
  InvoiceTypesEnum,
  PaymentStatusEnum,
} from '@bootstrap/types/invoices';
import { IStatusProps } from '@ui/status';

export const INVOICE_STATUS_TYPE_LIST: Record<InvoiceStatusEnum, IStatusProps['type']> = {
  CREATED: 'neutral',
  PENDING_DEBTOR_APPROVAL: 'warning',
  SUBMITTED: 'informative',
  PENDING_SIGN_OFF: 'warning',
  TO_BE_PAID: 'informative',
  OPEN: 'success',
  DISAPPROVED: 'error',
  FINALIZED: 'success',
  CLOSED: 'informative',
  APPROVED: 'success',
  ON_HOLD: 'warning',
};

export const INVOICE_PAYMENT_STATUS_TYPE_LIST: Record<PaymentStatusEnum, IStatusProps['type']> = {
  UNPAID: 'warning',
  OPEN: 'informative',
  PAID: 'success',
};

export const INVOICE_TYPE_LIST: Record<InvoiceTypesEnum, string> = {
  MERCHANT_DEBTOR: 'Merchant Debtor',
  MERCHANT_MERCHANT: 'Merchant Merchant',
  VENDOR_DEBTOR: 'Vendor Debtor',
  VENDOR_MERCHANT: 'Vendor Merchant',
  FACTOR_MERCHANT: 'Factor Merchant',
  FACTOR_VENDOR: 'Factor Vendor',
  FACTOR_PRODUCT: 'Factor Product',
  PRODUCT_VENDOR: 'Product Vendor',
  PRODUCT_MERCHANT: 'Product Merchant',
  PRODUCT_DEBTOR: 'Product Debtor',
};

export const COLLECTIVE_INVOICE_TYPE_LIST: Record<CollectiveInvoiceTypesEnum, string> = {
  COLLECTIVE_MERCHANT: 'Collective Merchant',
  COLLECTIVE_DEBTOR: 'Collective Debtor',
  COLLECTIVE_VENDOR: 'Collective Vendor',
  COLLECTIVE_PRODUCT: 'Collective Product',
};

export const INVOICE_STATUS_DEFAULT = InvoiceStatusEnum.CREATED;

export const INVOICE_PAYMENT_STATUS_DEFAULT = PaymentStatusEnum.OPEN;
