import { Flex } from '@ui/flex';
import { Space } from '@ui/space';
import {
  TableBottomWrapper,
  TableBulkActionsWrapper,
  TablePaginationWrapper,
  TableTopActionsWrapper,
  TableTopTitleWrapper,
  TableTopWrapper,
} from '@ui/table/TableAddons.styles';
import { ITableBottomProps, ITableTopProps } from '@ui/table/TableAddons.types';

export const TableTop = ({ title, filters, actions }: ITableTopProps) => {
  const isActionsTitleBlockShown = !!actions || !!title;
  const isTableSpaceShown = isActionsTitleBlockShown && !!filters;

  return (
    <TableTopWrapper>
      {isActionsTitleBlockShown && (
        <Flex justify="space-between" align="center" wrap="wrap">
          {title ?? <TableTopTitleWrapper>{title}</TableTopTitleWrapper>}
          {actions && <TableTopActionsWrapper>{actions}</TableTopActionsWrapper>}
        </Flex>
      )}
      {isTableSpaceShown && <Space height={16} />}
      {filters}
    </TableTopWrapper>
  );
};

export const TableBottom = ({ bulkActions, pagination }: ITableBottomProps) => {
  return (
    <TableBottomWrapper>
      {bulkActions && <TableBulkActionsWrapper>{bulkActions}</TableBulkActionsWrapper>}
      {pagination && <TablePaginationWrapper>{pagination}</TablePaginationWrapper>}
    </TableBottomWrapper>
  );
};
