/* eslint-disable no-console */
import Keycloak from 'keycloak-js';
import { useState, createContext, useContext, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { OneTimePage, FailureIcon } from '@bootstrap/components/one-time-page';
import { ReactComponent as Lock } from '@ui/assets/icons/lock.svg';
import { Button } from '@ui/button';
import { LiveChat } from '@ui/live-chat';
import { Typography, TypographyType } from '@ui/typography';

import { clientIdToProductName, getAccountApplicationListUrl, isUserAccessAllowed } from './auth-provider.utils';
import { useLoadLocaleData } from './hooks';
import { TokenData, UseAuthValues } from './types';

enum UserGroup {
  MERCHANT = 'merchants',
  DEBTOR = 'debtors',
  VENDOR = 'vendors',
  MANAGER = 'manager',
  BILLING_REQUEST_APPROVERS = 'billingRequestApprovers',
}

interface IUseUserGroup {
  userGroup: UserGroup;
  isMerchant: boolean;
  isBillingRequestApprover: boolean;
  isDebtor: boolean;
  sessionId?: string;
}

export const useUserGroup = (): IUseUserGroup => {
  const { getGroups, sessionId } = useAuth();
  const groups = getGroups();
  const userGroup = (groups[0] as UserGroup) || null;

  return {
    sessionId,
    userGroup,
    isMerchant: userGroup === UserGroup.MERCHANT,
    isBillingRequestApprover: userGroup === UserGroup.BILLING_REQUEST_APPROVERS,
    isDebtor: userGroup === UserGroup.DEBTOR,
  };
};

const AuthContext = createContext<Keycloak | undefined>(undefined);

export const useAuth = (): UseAuthValues => {
  const authContext = useContext(AuthContext);
  const {
    logout,
    clearToken,
    isTokenExpired,
    updateToken,
    authenticated,
    realm,
    realmAccess,
    resourceAccess,
    sessionId,
    token,
    tokenParsed,
    clientId,
  } = authContext || {};

  const tokenData = tokenParsed as TokenData;

  const getRoles = () => {
    return (
      tokenData?.realm_access?.roles.map((realmRole) => {
        const [role, id] = realmRole.split('_');
        return { role, id };
      }) || []
    );
  };
  const getGroups = () => (tokenData?.groups ? tokenData.groups.map((group) => group.replace('/', '')) : []);
  const getToken = () => authContext?.token;

  return {
    authenticated,
    realm,
    realmAccess,
    resourceAccess,
    sessionId,
    token,
    tokenParsed: tokenData,
    clientId,
    getGroups,
    getToken,
    logout,
    clearToken,
    isTokenExpired,
    updateToken,
    getRoles,
  } as UseAuthValues;
};

const useElement = (id: string) => {
  const [el, setEl] = useState<Element | null>(document.querySelector(`#${id}`));

  useEffect(() => {
    if (!el) {
      const node = document.createElement('div');
      node.id = id;
      document.body.appendChild(node);
      setEl(node);
    }
    return () => {
      if (el) {
        document.body.removeChild(el);
      }
    };
  }, [id]);

  return el;
};

export interface IAuthProviderProps {
  children: ReactNode;
  realm: string;
  url: string;
  clientId: string;
  tokenExpiredComponent: ReactNode;
  onTokenExpired?(tokenParser: Keycloak['tokenParsed']): void;
  onAuth?(tokenParser: Keycloak['tokenParsed']): void;
  environment: string;
}

export const AuthProvider = ({
  children,
  realm,
  url,
  clientId,
  tokenExpiredComponent,
  onTokenExpired,
  onAuth,
  environment,
}: IAuthProviderProps) => {
  const [error, setError] = useState(true);
  const [authData, setAuthData] = useState<Keycloak>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(true);
  const { language, setLanguage, messages } = useLoadLocaleData();
  const el = useElement('auth-modal');

  useEffect(() => {
    if (authData) {
      isUserAccessAllowed({ token: authData.token ?? '', clientId, environment }).then((allowed) => {
        setIsAccessAllowed(allowed);
      });
    }
  }, [clientId, authData, environment]);

  useEffect(() => {
    console.log('-Auth Provider- init');
    const keycloak = new Keycloak({
      realm,
      url,
      clientId,
    });

    keycloak.onAuthRefreshSuccess = function () {
      console.log('-Auth Provider- token refreshed');
      setAuthData(keycloak);
    };

    keycloak.onTokenExpired = function () {
      console.log('-Auth Provider- token expired');
      setTokenExpired(true);
      if (typeof onTokenExpired === 'function') {
        onTokenExpired(keycloak.tokenParsed);
      }
    };

    keycloak
      .init({ onLoad: 'login-required' })
      .then(function (isAuthenticated) {
        console.log('-Auth Provider- authenticated', isAuthenticated);
        setError(false);
        setAuthData(keycloak);
        setIsAuthenticated(isAuthenticated);
      })
      .catch((e) => {
        console.log('-Auth Provider- error', e);
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (typeof onAuth === 'function') {
      onAuth(authData?.tokenParsed);
    }
  }, [authData, onAuth]);

  return (
    <AuthContext.Provider value={authData}>
      {!error && !isAccessAllowed && (
        <IntlProvider locale={language} messages={messages}>
          <OneTimePage
            title={<FormattedMessage id="authProvider.oopsWrongAccess" />}
            description={
              <FormattedMessage
                id="authProvider.ourApologies"
                values={{
                  product: (
                    <Typography type={TypographyType.BODY_M_BOLD} as="span">
                      <FormattedMessage id={`product.${clientIdToProductName(clientId)}`} />
                    </Typography>
                  ),
                }}
              />
            }
            image={
              <FailureIcon>
                <Lock />
              </FailureIcon>
            }
            onLocaleSwitch={setLanguage}
            actions={[
              <LiveChat key="liveChat" />,
              <Button onClick={() => (window.location.href = getAccountApplicationListUrl(environment))} key="logIn">
                <FormattedMessage id="authProvider.logIntoADifferentAccount" />
              </Button>,
            ]}
          />
        </IntlProvider>
      )}
      {!error && isAccessAllowed && isAuthenticated && children}
      {tokenExpired && ReactDOM.createPortal(tokenExpiredComponent, el as HTMLElement)}
    </AuthContext.Provider>
  );
};
