export type Currency = 'EUR' | 'GBP';

export const DEFAULT_CURRENCY: Currency = 'EUR';

export const DEFAULT_ZERO_AMOUNT = '0.00';

export const CURRENCY_DECIMAL_SEPARATOR = ',';
export const CURRENCY_THOUSAND_SEPARATOR = '.';

export const formatCurrency = ({ value, currency }: { value: number; currency?: Currency | null }) => {
  /**
   * This will format Decimal value using a currency sign for given currency.
   * Return string formatted using internal Finqle money format.
   *      ("." - thousand separator, "," - decimal separator)
   * The format independent on language/localization and currency properties.
   * It's just use currency related sign for formatting.
   *
   * Examples:
   *    (20000.0, "EUR") -> € 20.000,00
   *    (-20000.0, "EUR") -> € -20.000,00
   *    (-20000.0, "GBP") -> £ -20.000,00
   *
   * WARNING!!! DO NOT CHANGE IT UNTIL ALIGNED WITH BUSINESS AND ALL DEVELOPMENT TEAMS
   */
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: currency ?? DEFAULT_CURRENCY,
  }).format(value);
};

export const FormattedCurrency = ({ value, currency }: { value: number; currency?: Currency | null }): JSX.Element => (
  <>{formatCurrency({ value, currency })}</>
);
