import styled, { css } from 'styled-components';

import { ISwitchProps } from './Switch.types';

const textStyles = css`
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const SwitchContainer = styled.span`
  position: relative;
  display: inline-block;
`;

const switchWidths = {
  small: '30px',
  default: '46px',
};
const switchHeights = {
  small: '16px',
  default: '24px',
};

export const StateContainer = styled.div<{ $size: ISwitchProps['size']; $isTextMode: boolean }>`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  width: ${({ $size = 'default' }) => switchWidths[$size]};
  height: ${({ $size = 'default' }) => switchHeights[$size]};
  background-color: var(--color-neutral-500);
  border: 1px solid var(--color-neutral-500);
  border-radius: 999px;

  ${({ $isTextMode }) =>
    $isTextMode &&
    css`
      min-width: 52px;
      width: auto;
      padding-right: 8px;
      background-color: var(--color-negative-300);
      border-color: var(--color-negative-300);
    `}
`;

export const StatePosition = styled.span<{ $size: ISwitchProps['size'] }>`
  flex-shrink: 0;
  width: ${({ $size = 'default' }) => ($size === 'default' ? '20px' : '12px')};
  height: ${({ $size = 'default' }) => ($size === 'default' ? '20px' : '12px')};
  background-color: var(--color-white);
  border-radius: 999px;
  box-shadow: var(--shadow-large);
`;

export const StateOn = styled.span`
  ${textStyles}
  color: var(--color-positive-700);
`;

export const StateOff = styled.span`
  ${textStyles}
  color: var(--color-negative-700);
`;

/** https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/ */
export const HiddenCheckbox = styled.input<{ $size: ISwitchProps['size']; $isTextMode: boolean }>`
  /* Keep the checkbox in the page flow but make it invisible */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  /* Hide it visually */
  opacity: 0;
  margin: 0;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    ${({ $isTextMode }) =>
      !$isTextMode &&
      css`
        &:hover + ${StateContainer} {
          background-color: var(--color-neutral-600);
          border-color: var(--color-neutral-600);
        }

        &:checked:hover + ${StateContainer} {
          background-color: var(--color-primary-500);
          border-color: var(--color-primary-500);
        }
      `}
  }

  &:checked + ${StateContainer} {
    background-color: var(--color-primary-700);
    border-color: var(--color-primary-700);
    justify-content: flex-end;

    ${({ $isTextMode }) =>
      $isTextMode &&
      css`
        padding-inline: 8px 0;
        background-color: var(--color-positive-400);
        border-color: var(--color-positive-400);
      `}
  }

  &:disabled {
    cursor: default;

    & + ${StateContainer} {
      background-color: var(--color-neutral-300);
      border-color: var(--color-neutral-300);
    }

    &:checked + ${StateContainer} {
      background-color: var(--color-primary-200);
      border-color: var(--color-primary-200);

      ${({ $isTextMode }) =>
        $isTextMode &&
        css`
          background-color: var(--color-neutral-300);
          border-color: var(--color-neutral-300);
        `}
    }

    & + ${StateContainer} > ${StatePosition} {
      box-shadow: none;
    }

    & + ${StateContainer} > ${StateOn}, & + ${StateContainer} > ${StateOff} {
      color: var(--color-neutral-600);
    }
  }

  &:focus-visible + ${StateContainer} {
    outline: 2px solid var(--color-primary-700);
    outline-offset: 1px;
  }
`;
