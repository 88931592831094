import { useRef, KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';

import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';
import { ReactComponent as Close } from '@ui/assets/icons/close.svg';
import { Typography, TypographyType } from '@ui/typography';

import { FilterChipStyles, CloseButton } from './FilterChip.styles';
import { IFilterChipProps } from './FilterChip.types';

export const FilterChip = ({ label, onDelete }: IFilterChipProps) => {
  const { formatMessage } = useIntl();
  const chipRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      onDelete();
    } else if (event.key === 'Escape') {
      chipRef.current?.blur();
    }
  };

  return (
    <FilterChipStyles ref={chipRef} tabIndex={0} onKeyDown={handleKeyDown}>
      <Typography type={TypographyType.BODY_S} as="span">
        {label}
      </Typography>
      <CloseButton
        onClick={onDelete}
        tabIndex={-1}
        aria-label={`${formatMessage({ id: 'filterChip.removeFilter' })} ${EN_DASH} ${label}`}
      >
        <Close />
      </CloseButton>
    </FilterChipStyles>
  );
};
