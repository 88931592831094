import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createContext, ReactElement } from 'react';

import { useAuth } from '@hub/auth-provider';
import DefaultDesktopLogo from '@ui/assets/img/finqle-logo-text.svg';
import DefaultMobileLogo from '@ui/assets/img/finqle-logo.svg';

export interface IApiContextProps {
  desktopLogo: string;
  mobileLogo: string;
  isDefaultLogo: boolean;
}

const ApiContext = createContext<IApiContextProps>({
  desktopLogo: DefaultDesktopLogo,
  mobileLogo: DefaultMobileLogo,
  isDefaultLogo: true,
});

interface IApiProviderProps {
  apiUrl: string;
  children: ReactElement;
}

export const ApiProvider = ({ apiUrl, children }: IApiProviderProps) => {
  const { token } = useAuth();

  if (!token) return children;

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: apiUrl,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }) as unknown as ApolloLink,
  });

  return (
    <ApiContext.Provider
      value={{
        desktopLogo: DefaultDesktopLogo,
        mobileLogo: DefaultMobileLogo,
        isDefaultLogo: true,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApiContext.Provider>
  );
};

export const ApiConsumer = ({ children }: { children: (context: IApiContextProps) => React.ReactNode }) => (
  <ApiContext.Consumer>{(context) => children(context)}</ApiContext.Consumer>
);
