import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import { IntlProvider } from 'react-intl';

import { TokenExpiredDialog } from '@bootstrap/components/TokenExpiredDialog';
import { ApiProvider, ApiConsumer, IApiContextProps } from '@bootstrap/context/api-provider';
import { AuthProvider } from '@hub/auth-provider';
import { Spinner } from '@ui/spinner';
import { Toaster } from '@ui/toast';

import { AppRouter } from './AppRouter';
import config from './constants';
import { MainTemplate } from './MainTemplate';

// Set Monday the first day of the week for en locale
dayjs.extend(utc);
dayjs.locale({
  ...en,
  weekStart: 1,
});

interface IAppProps {
  locale: string;
  messages: Record<string, string>;
}

export const App = ({ locale, messages }: IAppProps) => {
  return messages ? (
    <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
      <AuthProvider
        tokenExpiredComponent={<TokenExpiredDialog />}
        realm="MyFinqle"
        clientId="factoring-v2-frontend"
        url={config.AUTH_SERVER_URL}
        environment={config.ENVIRONMENT}
      >
        <ApiProvider apiUrl={config.GRAPHQL_URL}>
          <ApiConsumer>
            {(props: IApiContextProps) => (
              <MainTemplate {...props}>
                <Toaster />
                <AppRouter />
              </MainTemplate>
            )}
          </ApiConsumer>
        </ApiProvider>
      </AuthProvider>
    </IntlProvider>
  ) : (
    <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
      <Spinner size="md" message="Loading..." />
    </div>
  );
};
