import { forwardRef } from 'react';

import { InputStyles, InputContainerStyles, InputAccessoryLeft, InputAccessoryRight } from './TextInput.styles';
import { IBasicTextInputProps } from './TextInput.types';

export const BasicTextInput = forwardRef<HTMLInputElement, IBasicTextInputProps>(function BasicTextInput(
  { accessoryLeft, accessoryRight, ...props },
  ref,
) {
  return (
    <InputContainerStyles>
      {accessoryLeft && <InputAccessoryLeft>{accessoryLeft}</InputAccessoryLeft>}
      <InputStyles {...props} ref={ref} accessoryLeft={accessoryLeft} accessoryRight={accessoryRight} />
      {accessoryRight && <InputAccessoryRight>{accessoryRight}</InputAccessoryRight>}
    </InputContainerStyles>
  );
});
