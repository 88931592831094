import { useFormContext } from 'react-hook-form';

import { Control } from '@ui/control';

import { BasicTextInput } from './BasicTextInput';
import { ITextInputProps } from './TextInput.types';

export function TextInput({
  label,
  hidden,
  name,
  registerOptions,
  accessoryLeft,
  accessoryRight,
  helperText,
  direction,
  ...props
}: ITextInputProps) {
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  const registerProps = register(name, registerOptions);

  if (hidden) {
    return null;
  }

  return (
    <Control
      label={label}
      error={error?.message}
      isErrorShown={!!error}
      name={name}
      helperText={helperText}
      direction={direction}
    >
      <BasicTextInput
        {...props}
        {...registerProps}
        id={name}
        name={name}
        accessoryLeft={accessoryLeft}
        accessoryRight={accessoryRight}
        isErrorShown={!!error}
        aria-invalid={!!error}
        aria-required={!!registerOptions?.required}
        aria-describedby={`${name}-error`}
      />
    </Control>
  );
}
