import { useIntl } from 'react-intl';

import { ClientInvoiceStatusOption, CommonInvoiceFormValues, InvoiceFormFields } from './Invoice.types';

export const useClientInvoiceStatusOptions = (): Array<ClientInvoiceStatusOption> => {
  const { formatMessage } = useIntl();

  return [
    { value: 'ALL', label: formatMessage({ id: 'field.all' }) },
    { value: 'DRAFT', label: formatMessage({ id: 'status.draft' }) },
    { value: 'PENDING', label: formatMessage({ id: 'status.pending' }) },
    { value: 'OPEN', label: formatMessage({ id: 'status.open' }) },
    { value: 'DISAPPROVED', label: formatMessage({ id: 'status.disapproved' }) },
    { value: 'PAID', label: formatMessage({ id: 'status.paid' }) },
  ];
};

export const useClientTransactionStatementStatusOptions = (): Array<ClientInvoiceStatusOption> => {
  const { formatMessage } = useIntl();

  return [
    { value: 'ALL', label: formatMessage({ id: 'field.all' }) },
    { value: 'OPEN', label: formatMessage({ id: 'status.open' }) },
    { value: 'PAID', label: formatMessage({ id: 'status.paid' }) },
  ];
};

export const commonDefaultValues: CommonInvoiceFormValues = {
  [InvoiceFormFields.BRANCH]: null,
  [InvoiceFormFields.INVOICE_NUMBER]: '',
  [InvoiceFormFields.INVOICE_DATE]: new Date().toISOString(),
  [InvoiceFormFields.PO_NUMBER]: '',
  [InvoiceFormFields.REFERENCE]: '',
  [InvoiceFormFields.PROJECT_NAME]: '',
  [InvoiceFormFields.G_SPLIT_PERCENT]: 0,
  [InvoiceFormFields.DEPOT_PERCENT]: 0,
  [InvoiceFormFields.AMOUNT_WITH_VAT]: '0',
  [InvoiceFormFields.VAT_AMOUNT]: '0',
  [InvoiceFormFields.G_SPLIT_AMOUNT]: 0,
  [InvoiceFormFields.DIRECT_PAYMENT]: '0',
  [InvoiceFormFields.INDIRECT_PAYMENT]: 0,
  [InvoiceFormFields.ATTACHMENTS]: [],
  [InvoiceFormFields.INCLUDE_G_SLIT]: false,
  [InvoiceFormFields.INCLUDE_DEPOT_PERCENT]: false,
};
