import styled from 'styled-components';

export interface ISpaceProps {
  height: number;
}

const SpaceStyles = styled.div<ISpaceProps>`
  height: ${({ height }) => `${height}px`};
`;

export const Space = ({ height }: ISpaceProps) => {
  return <SpaceStyles height={height} />;
};
