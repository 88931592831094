import { FormattedMessage } from 'react-intl';

import { Notice } from '@ui/notice';

export function EmptyTable({
  isError,
  noData,
  children,
  noDataComponent = <Notice title={<FormattedMessage id="error.noAvailableData" />} />,
  errorComponent = <Notice title={<FormattedMessage id="error.somethingWentWrong" />} />,
}: {
  isError?: boolean;
  noData: boolean;
  children: JSX.Element;
  noDataComponent?: React.ReactElement<typeof Notice>;
  errorComponent?: React.ReactElement<typeof Notice>;
}) {
  if (isError) {
    return (
      <tbody>
        <tr style={{ display: 'flex' }}>
          <td style={{ width: '100%', display: 'flex', minHeight: '300px' }}>{errorComponent}</td>
        </tr>
      </tbody>
    );
  }

  if (noData) {
    return (
      <tbody>
        <tr style={{ display: 'flex' }}>
          <td style={{ width: '100%', display: 'flex', minHeight: '300px' }}>{noDataComponent}</td>
        </tr>
      </tbody>
    );
  }

  return children;
}
