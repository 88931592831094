import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { useMobile } from '@bootstrap/hooks/useMedia';
import { ReactComponent as ChevronLeft } from '@ui/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@ui/assets/icons/chevron-right.svg';
import { Button } from '@ui/button';
import { Flex } from '@ui/flex';
import { PageSize } from '@ui/page-size';
import { DEFAULT_PAGE_SIZE } from '@ui/page-size/PageSize.types';
import { Typography, TypographyType } from '@ui/typography';

import { IPaginationProps } from './Pagination.types';

export const Pagination = ({
  params,
  info,
  onChangeOffset,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageSizeChange,
}: IPaginationProps) => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const goToPage = (offset: number): void => {
    if (onChangeOffset) {
      return onChangeOffset(offset);
    }
    searchParams.set('offset', offset.toString());
    setSearchParams(searchParams);
  };

  const goToNextPage = () => {
    const offset = params.offset + params.limit;
    goToPage(offset);
  };

  const goToPrevPage = () => {
    const offset = params.offset - params.limit;
    goToPage(offset < 0 ? 0 : offset);
  };

  const startingNumber = info?.totalCount ? `${params.offset ? params.offset + 1 : 1} - ` : '';
  const endingNumber =
    params.offset + params.limit >= (info?.totalCount ?? 0) ? info?.totalCount : params.offset + params.limit;
  const range = [startingNumber, endingNumber].filter(Boolean).join(' ');

  return (
    <Flex align="center" justify="end" gap={isMobile ? 8 : 32}>
      {onPageSizeChange && <PageSize value={pageSize} onChange={onPageSizeChange} />}
      {range && info?.totalCount && (
        <Typography type={TypographyType.LABEL}>
          <FormattedMessage
            id="pagination.of"
            values={{
              range: range,
              count: info.totalCount,
            }}
          />
        </Typography>
      )}
      <Flex>
        <Button
          variant="secondary"
          onClick={goToPrevPage}
          iconLeft={<ChevronLeft />}
          disabled={!info?.hasPrevPage}
          aria-label={formatMessage({ id: 'pagination.goPrevPage' })}
        />
        <Button
          variant="secondary"
          onClick={goToNextPage}
          iconRight={<ChevronRight />}
          disabled={!info?.hasNextPage}
          aria-label={formatMessage({ id: 'pagination.goNextPage' })}
        />
      </Flex>
    </Flex>
  );
};
