import styled from 'styled-components';

export const TableTopWrapper = styled.div`
  padding: 24px;
  border-bottom: 1px solid var(--color-neutral-300);
`;

export const TableBottomWrapper = styled.div`
  border-top: 1px solid var(--color-neutral-300);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

export const TableBulkActionsWrapper = styled.div`
  margin-right: auto;
`;

export const TablePaginationWrapper = styled.div`
  margin-left: auto;
  padding: 24px 24px 24px 0;
`;

export const TableTopTitleWrapper = styled.div`
  margin-right: auto;
`;

export const TableTopActionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  gap: 16px;
  align-items: center;
`;
