import { SelectOptionType } from '@ui/select';

export const PAGE_SIZE_OPTIONS: number[] = [10, 20, 50, 100];
export type PageSizeOption = (typeof PAGE_SIZE_OPTIONS)[number];
export const DEFAULT_PAGE_SIZE: PageSizeOption = 10;
export interface IPageSizeProps {
  value: PageSizeOption;
  onChange: (limit: PageSizeOption) => void;
}

export enum PageSizeFormFields {
  PageSize = 'PAGE_SIZE',
}

export type PageSizeFormValues = {
  [PageSizeFormFields.PageSize]: SelectOptionType | null;
};
