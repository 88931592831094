import { ChangeEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useFormFilterManager } from '@bootstrap/hooks';
import { useDidUpdate } from '@bootstrap/hooks/useDidUpdate';
import { useStatePagination } from '@bootstrap/hooks/useStatePagination';
import { ReactComponent as ArrowDownward } from '@ui/assets/icons/arrow-downward.svg';
import { ReactComponent as ArrowUpward } from '@ui/assets/icons/arrow-upward.svg';
import { ReactComponent as ArrowsOutward } from '@ui/assets/icons/arrows-outward.svg';
import { ReactComponent as ChevronRight } from '@ui/assets/icons/chevron-right.svg';
import { ReactComponent as ExpandMore } from '@ui/assets/icons/expand-more.svg';
import { BulkActions } from '@ui/bulk-actions';
import { Button } from '@ui/button';
import { Checkbox } from '@ui/checkbox';
import { Pagination } from '@ui/pagination';
import { Switch } from '@ui/switch';

import { EmptyTable } from './EmptyTable';
import { ExpandedComponent } from './ExpandedComponent';
import {
  TableOverflowWrapper,
  TableStyles,
  TableHeadRowStyles,
  TableRowStyles,
  TableHeadCell,
  TableHeadCellButton,
  TableCellStyles,
  TableFooterWrapper,
  TableFooterRowStyles,
  TableFooterCellStyles,
  TableHead,
  TableLoaderStyles,
} from './Table.styles';
import { ITableProps, SortDirection } from './Table.types';
import { TableBottom } from './TableAddons';
import { useTableReducer } from './tableReducer';

export function Table<D extends Record<string, unknown>>({
  data: allRows,
  columns,
  keyField,
  selectable,
  selectableIndicator = 'checkbox',
  selectableRowSelected,
  selectableRowDisabled,
  onSelectedRowsChange,
  defaultSortField,
  defaultSortDirection,
  onSort,
  isLoading,
  noDataComponent,
  errorComponent,
  isError,
  isHeaderHidden,
  isBodyHidden,
  onRowClick,
  isRowActive,
  expandableRowComponent,
  expandableRowsExpanded,
  onExpandRow,
  autoClearRows,
  loadingHeight,
  hasStatePagination,
  disableSelectedBackground = false,
}: ITableProps<D>) {
  const { formatMessage } = useIntl();
  const hasFooter = columns.some((column) => column.Footer) && allRows.length !== 0;
  const noData = allRows.length === 0 && !isLoading;
  const selectableRows = selectableRowDisabled ? allRows.filter((row) => !selectableRowDisabled(row)) : allRows;
  const disabledRows = selectableRowDisabled ? allRows.filter((row) => selectableRowDisabled(row)) : [];
  const { getSearchParam } = useFormFilterManager();
  const offset = getSearchParam('offset');

  const [
    {
      selectedRows,
      allSelected,
      selectedCount,
      selectedColumn,
      sortDirection,
      toggleOnSelectedRowsChange,
      expandedRows,
      restingCheckbox,
    },
    dispatch,
  ] = useTableReducer<D>({
    sortDirection: defaultSortDirection,
    selectedColumn: columns.find((column) => !!defaultSortField && column.sortField === defaultSortField),
  });
  const indeterminate = selectedRows.length > 0 && !allSelected && !restingCheckbox;
  const columnsCount = columns.length + (selectable ? 1 : 0) + (expandableRowComponent ? 1 : 0);

  useEffect(() => {
    if (!selectableRowSelected || allRows.length === 0) return;
    const preselectedRows = allRows.filter(selectableRowSelected);
    dispatch({
      type: 'SELECT_MULTIPLE_ROWS',
      selectedRows: preselectedRows,
      disabledRows: disabledRows,
      selectableRowsCount: selectableRows.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRows, selectableRowSelected]);

  useEffect(() => {
    if (!expandableRowsExpanded) return;
    const preExpandedRows = allRows.filter(expandableRowsExpanded);

    dispatch({
      type: 'PRE_EXPAND_ROWS',
      preExpandedRows: preExpandedRows,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRows, expandableRowsExpanded]);

  useDidUpdate(() => {
    onSelectedRowsChange?.({ allSelected, selectedCount, selectedRows: selectedRows.slice(0) });
    // onSelectedRowsChange trigger is controlled by toggleOnSelectedRowsChange state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleOnSelectedRowsChange]);

  useDidUpdate(() => {
    selectedColumn && onSort?.(selectedColumn, sortDirection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumn, sortDirection]);

  useEffect(() => {
    if (autoClearRows) {
      dispatch({ type: 'CLEAR_SELECTED_ROWS' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoClearRows]);

  useEffect(() => {
    if (offset) {
      // Reset checkboxes when changing page
      dispatch({ type: 'RESET_CHECKBOXES' });
    }
  }, [offset, dispatch]);

  const { data: paginatedData, info, params, onChangeOffset } = useStatePagination(allRows);
  const renderedData = hasStatePagination ? paginatedData : allRows;

  return (
    <>
      <TableOverflowWrapper>
        <TableStyles>
          <EmptyTable
            noData={noData}
            isError={isError}
            noDataComponent={noDataComponent}
            errorComponent={errorComponent}
          >
            <>
              {!isHeaderHidden && (
                <TableHead>
                  <TableHeadRowStyles>
                    {expandableRowComponent && <TableHeadCell boxSizing="content-box" width="28px" />}
                    {selectable && (
                      <TableHeadCell
                        boxSizing="content-box"
                        width={selectableIndicator === 'checkbox' ? '28px' : '30px'}
                      >
                        {selectableIndicator === 'checkbox' && (
                          <Checkbox
                            checked={allSelected}
                            indeterminate={indeterminate}
                            onChange={(event) => {
                              if (event.target.checked) {
                                dispatch({
                                  type: 'SELECT_ALL_ROWS',
                                  selectableRows: selectableRows,
                                });
                              } else {
                                dispatch({ type: 'CLEAR_SELECTED_ROWS' });
                              }
                            }}
                          />
                        )}
                      </TableHeadCell>
                    )}
                    {columns.map((column) => {
                      const isCurrentColumnSorted = selectedColumn?.id === column.id;
                      return (
                        <TableHeadCell
                          as="th"
                          key={column.id}
                          right={column.right}
                          width={column.width}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          aria-sort={
                            !isCurrentColumnSorted
                              ? 'none'
                              : sortDirection === SortDirection.ASC
                                ? 'ascending'
                                : 'descending'
                          }
                          style={column.headerStyle}
                        >
                          {column?.sortable ? (
                            <TableHeadCellButton
                              right={column.right}
                              onClick={() => {
                                dispatch({
                                  type: 'SORT_CHANGE',
                                  sortDirection:
                                    sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
                                  selectedColumn: column,
                                });
                              }}
                            >
                              <span>{column.name}</span>
                              {!isCurrentColumnSorted ? (
                                <ArrowsOutward aria-hidden="true" />
                              ) : sortDirection === SortDirection.DESC ? (
                                <ArrowDownward aria-hidden="true" />
                              ) : (
                                <ArrowUpward aria-hidden="true" />
                              )}
                            </TableHeadCellButton>
                          ) : (
                            column.name
                          )}
                        </TableHeadCell>
                      );
                    })}
                  </TableHeadRowStyles>
                </TableHead>
              )}
              {!isBodyHidden && (
                <tbody>
                  {isLoading ? (
                    <tr>
                      <TableLoaderStyles isLoading={isLoading} colSpan={columnsCount} height={loadingHeight} />
                    </tr>
                  ) : (
                    renderedData.map((row, rowIndex) => {
                      const selected = selectedRows.some((selectedRow) => selectedRow[keyField] === row[keyField]);
                      const disabled = selectableRowDisabled?.(row);
                      const selectableIndicatorProps = {
                        checked: selected,
                        disabled,
                        onChange: (event: ChangeEvent<HTMLInputElement>) => {
                          dispatch({
                            type: 'SELECT_SINGLE_ROW',
                            keyField,
                            row,
                            isSelected: event.target.checked,
                            selectableRowsCount: selectableRows.length,
                          });
                        },
                      };
                      const expanded = expandedRows.some((expandedRow) => expandedRow[keyField] === row[keyField]);
                      const onExpand = () => {
                        onExpandRow?.(row);
                        dispatch({
                          type: 'EXPAND_ROW',
                          keyField,
                          row,
                          isExpanded: !expanded,
                        });
                      };

                      return [
                        <TableRowStyles
                          key={row[keyField] as string | number}
                          selected={selected}
                          selectable={Boolean(selectable) && !disabled}
                          onClick={(event) => {
                            const target = event.target as HTMLElement;
                            if (target.tagName === 'INPUT' || target.dataset['type'] === 'checkbox') {
                              return;
                            }
                            onRowClick?.(row);
                          }}
                          tabIndex={onRowClick ? 0 : undefined}
                          clickable={!!onRowClick}
                          active={!!isRowActive?.(row)}
                          disableSelectedBackground={disableSelectedBackground}
                        >
                          {expandableRowComponent && (
                            <TableCellStyles width="28px" boxSizing="content-box">
                              <Button
                                size="small"
                                variant="secondary"
                                iconLeft={expanded ? <ExpandMore /> : <ChevronRight />}
                                onClick={onExpand}
                                aria-expanded={expanded}
                                aria-label={
                                  expanded
                                    ? formatMessage({ id: 'table.collapseRow' })
                                    : formatMessage({ id: 'table.expandRow' })
                                }
                              />
                            </TableCellStyles>
                          )}
                          {selectable && (
                            <TableCellStyles
                              boxSizing="content-box"
                              width={selectableIndicator === 'checkbox' ? '28px' : '30px'}
                            >
                              {selectableIndicator === 'checkbox' ? (
                                <Checkbox {...selectableIndicatorProps} />
                              ) : (
                                <Switch size="small" {...selectableIndicatorProps} />
                              )}
                            </TableCellStyles>
                          )}
                          {columns.map((column, columnIndex) => {
                            return (
                              <TableCellStyles
                                key={column.id ?? columnIndex}
                                right={column.right}
                                width={column.width}
                                minWidth={column.minWidth}
                                maxWidth={column.maxWidth}
                                style={column.style}
                              >
                                {column.cell(row, rowIndex, column)}
                              </TableCellStyles>
                            );
                          })}
                        </TableRowStyles>,
                        expanded && (
                          <ExpandedComponent key={`${row[keyField]}_${rowIndex}`} colSpan={columnsCount}>
                            {expandableRowComponent?.(row)}
                          </ExpandedComponent>
                        ),
                      ];
                    })
                  )}
                </tbody>
              )}
              {hasFooter && !isLoading && (
                <TableFooterWrapper>
                  <TableFooterRowStyles>
                    {expandableRowComponent && (
                      <TableHeadCell
                        boxSizing="content-box"
                        width={selectableIndicator === 'checkbox' ? '28px' : '30px'}
                      />
                    )}
                    {selectable && (
                      <TableCellStyles
                        boxSizing="content-box"
                        width={selectableIndicator === 'checkbox' ? '28px' : '30px'}
                      />
                    )}
                    {columns.map((column) => (
                      <TableFooterCellStyles
                        key={column.id}
                        right={column.right}
                        width={column.width}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                      >
                        {column.Footer}
                      </TableFooterCellStyles>
                    ))}
                  </TableFooterRowStyles>
                </TableFooterWrapper>
              )}
            </>
          </EmptyTable>
        </TableStyles>
      </TableOverflowWrapper>
      {hasStatePagination && (
        <TableBottom
          bulkActions={
            <BulkActions>
              <BulkActions.SelectedCount count={selectedCount} />
            </BulkActions>
          }
          pagination={<Pagination params={params} info={info} onChangeOffset={onChangeOffset} />}
        />
      )}
    </>
  );
}
