import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@ui/button';
import { Notice } from '@ui/notice';

interface ITableErrorCardProps {
  titleKey: string;
  refetch: () => void;
}

export const TableErrorCard = ({ titleKey, refetch }: ITableErrorCardProps) => {
  const { formatMessage } = useIntl();
  return (
    <Notice
      title={formatMessage({ id: titleKey })}
      actions={[
        <Button onClick={() => refetch()} key="fetch">
          <FormattedMessage id="action.tryAgain" />
        </Button>,
      ]}
    />
  );
};
