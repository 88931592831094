import { SelectOptionType } from '@ui/select';

export type ClientInvoiceStatus = 'DRAFT' | 'PENDING' | 'OPEN' | 'PAID' | 'DISAPPROVED';
export type ClientTransactionStatementStatus = 'OPEN' | 'PAID';
export type ClientInvoiceStatusOption = { value: ClientInvoiceStatus | 'ALL'; label: string };

export interface IVatRateOption {
  label: string;
  value: VatRateLevel;
  data: VatRate;
}

export enum InvoiceFormFields {
  PDF = 'pdf',
  BRANCH = 'branch',
  INVOICE_NUMBER = 'invoiceNumber',
  INVOICE_DATE = 'invoiceDate',
  PO_NUMBER = 'poNumber',
  REFERENCE = 'reference',
  PROJECT_NAME = 'projectName',
  PAYMENT_TERM = 'paymentTerm',
  G_SPLIT_PERCENT = 'gSplitPercent',
  AMOUNT_WITH_VAT = 'amountWithVat',
  VAT_AMOUNT = 'vatAmount',
  G_SPLIT_AMOUNT = 'gSplitAmount',
  DEPOT_PERCENT = 'depotPercent',
  DIRECT_PAYMENT = 'directPayment',
  INDIRECT_PAYMENT = 'indirectPayment',
  INCLUDE_G_SLIT = 'includeGSlit',
  INCLUDE_DEPOT_PERCENT = 'includeDepotPercent',
  UNIT_LINE_ITEMS = 'unitLineItems',
  ATTACHMENTS = 'attachments',
}

export type CommonInvoiceFormValues = {
  [InvoiceFormFields.BRANCH]: SelectOptionType | null;
  [InvoiceFormFields.INVOICE_NUMBER]: string;
  [InvoiceFormFields.INVOICE_DATE]: string;
  [InvoiceFormFields.PO_NUMBER]: string;
  [InvoiceFormFields.REFERENCE]: string;
  [InvoiceFormFields.PROJECT_NAME]: string;
  [InvoiceFormFields.G_SPLIT_PERCENT]: number;
  [InvoiceFormFields.INCLUDE_G_SLIT]: boolean;
  [InvoiceFormFields.AMOUNT_WITH_VAT]: string;
  [InvoiceFormFields.VAT_AMOUNT]: string;
  [InvoiceFormFields.G_SPLIT_AMOUNT]: number;
  [InvoiceFormFields.DIRECT_PAYMENT]: string;
  [InvoiceFormFields.DEPOT_PERCENT]: number;
  [InvoiceFormFields.INDIRECT_PAYMENT]: number;
  [InvoiceFormFields.INCLUDE_DEPOT_PERCENT]: boolean;
  [InvoiceFormFields.ATTACHMENTS]: File[];
};
