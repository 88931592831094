import dayjs from 'dayjs';
import { forwardRef } from 'react';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DISPLAY_DATE_FORMAT } from '@bootstrap/constants/date';
import { ReactComponent as CalendarMonth } from '@ui/assets/icons/calendar-month.svg';
import { Control } from '@ui/control';
import { ITextInputProps, BasicTextInput } from '@ui/text-input';

import './Datepicker.css';

export interface IDatepickerProps
  extends Omit<ITextInputProps, 'accessoryLeft' | 'inputMode' | 'accessoryRight' | 'isErrorShown' | 'align'> {
  /**
   * @description
   * https://flatpickr.js.org/options/
   */
  options?: DateTimePickerProps['options'];
}

export const Datepicker = forwardRef<Flatpickr, IDatepickerProps>(function Datepicker(
  { name, registerOptions, hidden, label, helperText, direction, options, ...props },
  ref,
) {
  const { formatMessage } = useIntl();
  const { control, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  if (hidden) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field: { onChange, ...fieldProps } }) => (
        <Flatpickr
          {...fieldProps}
          ref={ref}
          options={{
            formatDate(date) {
              return dayjs(date).format(DISPLAY_DATE_FORMAT);
            },
            ...options,
          }}
          onChange={(dates: Date[]) => {
            /** If mode === 'single', Datepicker will return value as a string instead of an array. */
            if (options?.mode === 'multiple' || options?.mode === 'range') {
              onChange(dates);
            } else {
              onChange(dates[0]);
            }
          }}
          render={(_, ref) => (
            <Control
              label={label}
              error={error?.message}
              isErrorShown={!!error}
              name={name}
              direction={direction}
              helperText={helperText}
            >
              <BasicTextInput
                placeholder={formatMessage({ id: 'action.select' })}
                name={name}
                id={name}
                ref={ref}
                accessoryRight={<CalendarMonth />}
                isErrorShown={!!error}
                aria-required={
                  typeof registerOptions?.required === 'string' || typeof registerOptions?.required === 'boolean'
                    ? !!registerOptions?.required
                    : registerOptions?.required?.value
                }
                aria-invalid={!!error}
                aria-describedby={`${name}-error`}
                {...props}
              />
            </Control>
          )}
        />
      )}
    />
  );
});
