import { useIntl } from 'react-intl';

import { AppRoutesProps } from '@bootstrap/configs/route-access';
import { FEATURE_FLAGS } from '@factoring/configs/featureFlags';
import { ReactComponent as CompareArrows } from '@ui/assets/icons/compare_arrows.svg';
import { ReactComponent as Euro } from '@ui/assets/icons/euro.svg';
import { ReactComponent as Gavel } from '@ui/assets/icons/gavel.svg';
import { ReactComponent as Group } from '@ui/assets/icons/group.svg';
import { ReactComponent as GroupeAdd } from '@ui/assets/icons/group_add.svg';
import { ReactComponent as Home } from '@ui/assets/icons/home.svg';
import { ReactComponent as NoteStack } from '@ui/assets/icons/note_stack.svg';
import { ReactComponent as Settings } from '@ui/assets/icons/settings.svg';

export const useRoutes = (): AppRoutesProps[] => {
  const { formatMessage } = useIntl();
  return [
    {
      path: '/',
      title: formatMessage({ id: 'field.dashboard' }),
      icon: <Home />,
      groups: ['merchants'],
    },
    {
      path: '/invoicing',
      title: formatMessage({ id: 'field.invoicing' }),
      groups: ['merchants'],
      subRoutes: [
        {
          path: '/invoices',
          title: formatMessage({ id: 'field.invoices' }),
          icon: <NoteStack />,
          groups: ['merchants'],
        },
        {
          path: '/invoices/:id',
          title: formatMessage({ id: 'field.invoices' }),
          parent: '/invoices',
          hidden: true,
        },
        {
          path: '/invoices/upload',
          title: formatMessage({ id: 'field.invoices' }),
          parent: '/invoices',
          hidden: true,
        },
        {
          path: '/invoices/upload/:id',
          title: formatMessage({ id: 'field.invoices' }),
          parent: '/invoices',
          hidden: true,
        },
        {
          path: '/invoices/create',
          title: formatMessage({ id: 'field.invoices' }),
          parent: '/invoices',
          hidden: true,
        },
        {
          path: '/invoices/edit/:id',
          title: formatMessage({ id: 'field.invoices' }),
          parent: '/invoices',
          hidden: true,
        },
        {
          path: '/statements/transactions',
          title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
          parent: '/statements',
          groups: ['merchants'],
          icon: <Euro />,
          hidden: FEATURE_FLAGS.TRANSACTION_STATEMENTS,
        },
        {
          path: '/statements/transactions/:id',
          title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
          parent: '/statements',
          groups: ['merchants'],
          hidden: true,
        },
        {
          path: '/transaction-statements',
          title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
          parent: '/statements',
          groups: ['merchants'],
          icon: <Euro />,
          hidden: !FEATURE_FLAGS.TRANSACTION_STATEMENTS,
        },
        {
          path: '/transaction-statements/:id',
          title: formatMessage({ id: 'transactionStatement.transactionStatements' }),
          parent: '/statements',
          groups: ['merchants'],
          hidden: true,
        },
        {
          path: '/settlements',
          title: formatMessage({ id: 'field.settlements' }),
          icon: <CompareArrows />,
          groups: ['merchants'],
        },
      ],
    },
    {
      path: '/relations',
      title: formatMessage({ id: 'field.relations' }),
      groups: ['merchants'],
      subRoutes: [
        {
          path: '/accounts',
          title: formatMessage({ id: 'field.accounts' }),
          icon: <Group />,
          groups: ['merchants'],
        },
        {
          path: '/accounts/new',
          title: formatMessage({ id: 'field.accounts' }),
          parent: '/accounts',
          groups: ['merchants'],
          hidden: true,
        },
        {
          path: '/accounts/:id',
          title: formatMessage({ id: 'field.accounts' }),
          parent: '/accounts',
          groups: ['merchants'],
          hidden: true,
        },
      ],
    },
    {
      path: '/legal',
      title: formatMessage({ id: 'field.legal' }),
      groups: ['merchants'],
      hidden: !FEATURE_FLAGS.TRANSFER_STATEMENTS,
      subRoutes: [
        {
          path: '/transfer-statements',
          parent: '/legal',
          title: formatMessage({ id: 'transferStatement.transferStatements' }),
          groups: ['merchants'],
          icon: <Gavel />,
        },
        {
          path: '/transfer-statements/:id',
          parent: '/legal',
          title: formatMessage({ id: 'transferStatement.transferStatements' }),
          groups: ['merchants'],
          hidden: true,
        },
      ],
    },
    {
      path: '/settings',
      title: formatMessage({ id: 'field.settings' }),
      hidden: true,
      subRoutes: [
        {
          path: '/settings/profile',
          parent: '/settings',
          title: formatMessage({ id: 'companyProfile.title' }),
          groups: ['merchants'],
          icon: <Settings />,
          hidden: true,
        },
        {
          path: '/settings/users',
          parent: '/settings',
          title: formatMessage({ id: 'field.users' }),
          groups: ['merchants'],
          icon: <GroupeAdd />,
          hidden: true,
        },
        {
          path: '/settings/users/:id',
          parent: '/settings',
          title: formatMessage({ id: 'field.user' }),
          groups: ['merchants'],
          hidden: true,
        },
      ],
    },
    {
      path: '/logout',
      title: formatMessage({ id: 'action.logout' }),
      hidden: true,
    },
  ];
};
