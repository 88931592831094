import styled from 'styled-components';

export const BulkActionsWrapper = styled.div<{ noDivider?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 24px 24px 52px;
  background-color: var(--color-white);
  border-top: none;
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  gap: 16px;

  &::before {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    border-left: 1px solid var(--color-neutral-400);
    border-bottom: 1px solid var(--color-neutral-400);
    border-radius: 0 0 0 var(--border-radius-default);
    position: absolute;
    left: 24px;
    top: 24px;
  }
`;
