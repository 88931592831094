import { useEffect, useState } from 'react';

import { DEFAULT_LOCALE, LOCAL_STORAGE_LOCALE_KEY } from '@bootstrap/constants/locale';
import { useLocalStorage } from '@bootstrap/hooks/useStorage';
import { LanguageEnum, Locale } from '@bootstrap/types/locale';
import { getLocaleFromNavigator } from '@bootstrap/utils/locale';

export const useLoadLocaleData = ({
  loadLocaleData,
  supportedLocales,
}: {
  loadLocaleData: (locale: Locale) => Promise<Record<string, string>>;
  supportedLocales?: LanguageEnum[];
}) => {
  const [locale, setLocale] = useLocalStorage<Locale>(
    LOCAL_STORAGE_LOCALE_KEY,
    getLocaleFromNavigator(supportedLocales) || DEFAULT_LOCALE,
  );
  const [messages, setMessages] = useState<Record<string, string>>();

  useEffect(() => {
    const asyncEffect = async () => {
      const data = await loadLocaleData(locale);
      setMessages(data);
    };

    void asyncEffect();
  }, [locale, loadLocaleData]);

  return {
    locale,
    setLocale,
    messages,
  };
};
