import { useRef, KeyboardEvent, forwardRef, MutableRefObject } from 'react';

import { ChoiceChipStyles } from '@ui/choice-chip/ChoiceChip.styles';
import { IChoiceChip } from '@ui/choice-chip/ChoiceChip.types';
import { Typography, TypographyType } from '@ui/typography';

export const ChoiceChip = forwardRef<HTMLDivElement, IChoiceChip>(
  ({ label, checked, disabled, onClick }, forwardedRef) => {
    const innerRef = useRef<HTMLButtonElement>(null);
    const combinedRef = (forwardedRef ?? innerRef) as MutableRefObject<HTMLButtonElement>;

    const handleClick = () => {
      if (!disabled && !checked) {
        onClick();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!disabled && !checked && ['Space', 'Enter'].includes(event.code)) {
        combinedRef.current?.click();
      }
    };

    return (
      <ChoiceChipStyles
        ref={combinedRef}
        type="button"
        disabled={disabled}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        aria-pressed={checked}
      >
        <Typography type={TypographyType.BODY_M_REGULAR} as="span">
          {label}
        </Typography>
      </ChoiceChipStyles>
    );
  },
);

ChoiceChip.displayName = 'ChoiceChip';
