const enum MixpanelEventName {
  FACTORING_NEW_INVOICE = 'Factoring invoice creation - New invoice ',
  FACTORING_UPLOAD_INVOICE = 'Factoring invoice creation - Upload invoice - Add invoice ',
  FACTORING_UPLOAD_INVOICE_CANCEL = 'Factoring invoice creation - Upload invoice - Cancel',
  FACTORING_UPLOAD_INVOICE_SUBMIT = 'Factoring invoice creation - Upload invoice - Submit',
  FACTORING_UPLOAD_INVOICE_DELETE = 'Factoring invoice creation - Upload invoice - Delete',
  FACTORING_UPLOAD_INVOICE_EDIT = 'Factoring invoice creation - Upload invoice - Edit',
  FACTORING_UPLOAD_INVOICE_SAVE_AS_DRAFT = 'Factoring invoice creation - Upload invoice - Save as draft',
  FACTORING_CREATE_INVOICE = 'Factoring invoice creation - Create invoice - Add invoice',
  FACTORING_CREATE_INVOICE_CANCEL = 'Factoring invoice creation - Create invoice - Cancel',
  FACTORING_CREATE_INVOICE_SUBMIT = 'Factoring invoice creation - Create invoice - Submit',
  FACTORING_CREATE_INVOICE_SAVE_AS_DRAFT = 'Factoring invoice creation - Create invoice - Save as draft',
  FACTORING_CREATE_INVOICE_DELETE = 'Factoring invoice creation - Create invoice - Delete',
  FACTORING_CREATE_INVOICE_ADD_UNITS = 'Factoring invoice creation - Create invoice - Add units',
  FACTORING_INVOICES_BULK_ACTION_SUBMIT = 'Factoring invoices - Bulk action - Submit',
  FACTORING_INVOICES_BULK_ACTION_CANCEL = 'Factoring invoices - Bulk action - Cancel',
  FACTORING_INVOICES_FILTERS = 'Factoring invoices - Filters',
  FACTORING_INVOICE_DOWNLOAD_PDF = 'Factoring invoice - Download PDF',
  FACTORING_TRANSACTION_STATEMENTS_BULK_ACTION_DOWNLOAD = 'Transaction statements - Bulk action - Download',
  FACTORING_TRANSACTION_STATEMENT_DOWNLOAD = 'Transaction statement - Download',
  FACTORING_SETTLEMENTS_FILTERS = 'Factoring settlements - Filters',
  FACTORING_ACCOUNTS_ADD_ACCOUNT = 'Factoring accounts - Add account',
  FACTORING_ACCOUNTS_ADD_ACCOUNT_SUBMIT = 'Factoring accounts - Add  - Submit',
  FACTORING_ACCOUNTS_SEARCH_COMPANY_NEXT_STEP = 'Factoring accounts - Search company - Next step',
  FACTORING_ACCOUNTS_ACCOUNT_INFORMATION_NEXT_STEP = 'Factoring accounts - Account information - Next step',
  FACTORING_ACCOUNTS_ACCOUNT_INFORMATION_PREVIOUS_STEP = 'Factoring accounts - Account information - Previous step',
  FACTORING_ACCOUNTS_ATTACHMENTS_PREVIOUS_STEP = 'Factoring accounts - Attachments - Previous step',
  FACTORING_TRANSFER_STATEMENTS_BULK_ACTION_DOWNLOAD = 'Transfer statements - Bulk action - Download',
  FACTORING_TRANSFER_STATEMENT_DOWNLOAD = 'Transfer statement - Download',
  FACTORING_COMPANY_PROFILE_COPY_UBL = 'Factoring company profile - Copy UBL',
  FACTORING_USERS_ADD_USER = 'Factoring users - Add user',
  FACTORING_USERS_SUBMIT_USER = 'Factoring users - Submit user',
}

export default MixpanelEventName;
