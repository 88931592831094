import styled from 'styled-components';

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxContainer = styled.div`
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-neutral-400);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);

  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;

export const CheckboxLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: baseline;
  gap: 12px;

  /** Fallback for browsers not supporting :has selector */
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  @media (max-width: 768px) {
    align-items: flex-start;
  }

  &:has(input[type='checkbox']:disabled) {
    cursor: default;
  }

  > input[type='checkbox']:checked
    + ${CheckboxContainer},
    > input[type='checkbox']:indeterminate
    + ${CheckboxContainer} {
    background-color: var(--color-primary-700);
    border-color: var(--color-primary-700);
  }
  > input[type='checkbox']:not(:checked):disabled + ${CheckboxContainer} {
    background-color: var(--color-neutral-200);
    border-color: var(--color-neutral-400);
  }
  > input[type='checkbox']:checked:disabled + ${CheckboxContainer} {
    background-color: var(--color-neutral-400);
    border-color: var(--color-neutral-400);

    & > svg {
      color: var(--color-neutral-200);
    }
  }
  > input[type='checkbox']:not(:disabled, :checked, :indeterminate) + ${CheckboxContainer}:hover {
    background-color: var(--color-primary-200);
    border: 1px solid var(--color-primary-500);
  }

  > input[type='checkbox']:focus-visible + ${CheckboxContainer} {
    outline: 2px solid var(--color-primary-700);
    outline-offset: 1px;
  }
`;
