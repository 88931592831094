import { IStandardPaginationParamsType, IPaginationParamsType } from '@bootstrap/types/pagination';

export const defaultPaginationParams: Readonly<IPaginationParamsType> = Object.freeze({
  /**
   * number of items per page
   */
  first: 10,
  skip: 0,
  orderBy: '-created',
});

export const standardPaginationParams: Readonly<IStandardPaginationParamsType> = Object.freeze({
  /**
   * number of items per page
   */
  limit: 10,
  offset: 0,
});

export const defaultPaginationInfo: Readonly<{
  totalCount: number;
  hasNextPage: boolean;
}> = Object.freeze({
  totalCount: 0,
  hasNextPage: false,
});
